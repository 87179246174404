
import React from 'react'
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import registerServiceWorker from './registerServiceWorker';
import { ToastProvider } from 'react-toast-notifications';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import './index.css';

import reducer from './reducers'
import Root from './router/Root'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['reactions', 'replies'],
  blacklist: ['objects', 'user', 'feeds'],
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, reducer)


const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunkMiddleware,
    createLogger({ collapsed: true })
  )
)

let persistor = persistStore(store)

//persistor.purge()

ReactDOM.render(
  <ToastProvider >
    <PersistGate loading={null} persistor={persistor}>
      <Root store={store} />
    </PersistGate>
  </ToastProvider>,
  document.getElementById('root')
)
registerServiceWorker();


