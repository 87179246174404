import { API_HOST } from "../config/Config";

import {
	ADD_REPLY,
	ADD_REPLY_SUCCESS,
	FETCH_REPLIES,
	FETCH_REPLIES_SUCCESS,
	FAILURE,
	DELETE_REPLY_SUCCESS
} from "../constants/ActionTypes";

import { sendMessage } from "./socket"
import { getMinOrder, getMinOrderWithContent } from "../utils/replies";


export function addReplySuccess(reply) {
	return {
		type: ADD_REPLY_SUCCESS,
		id: reply.id,
		reply
	};
}


export function fetchRepliesSuccess(replies) {
	return {
		type: FETCH_REPLIES_SUCCESS,
		replies
	};
}


const addReplyContentAPI = (id, content, style, actorId) => {
	return function (dispatch) {
		dispatch({ type: ADD_REPLY });
		return fetch(`${API_HOST}/v1/reply/${id}/update`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				content,
				style,
				actorId
			})
		})
			.then(response => response.json())
			.then(json => {
				dispatch(addReplySuccess(json))
				// dispatch(sendMessage(json))
			});
	};
}

export const addReplyContent = (id, content, style) => (dispatch, getState) => {
	const actorId = getState().user.id
	return dispatch(addReplyContentAPI(id, content, style, actorId))
};


const addReplyAPI = (content, href, inReplyTo, attributedTo, top, left, pid, isNSFW) => {
	return function (dispatch) {
		dispatch({ type: ADD_REPLY });
		return fetch(`${API_HOST}/v1/reply`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				attributedTo,
				content,
				href,
				inReplyTo,
				top,
				left,
				pid,
				isNSFW
			})
		})
			.then(response => response.json())
			.then(json => {
				dispatch(addReplySuccess(json))
				dispatch(sendMessage(json))
			});
	};
}

export const addReply = (content, href, inReplyTo, top, left, pid, isNSFW) => (dispatch, getState) => {
	const actorId = getState().user.id
	return dispatch(addReplyAPI(content, href, inReplyTo, actorId, top, left, pid, isNSFW))
};

const __fetchRepliesForInReplyToIdsAPI = (inReplyToIds) => {
	return function (dispatch) {
		dispatch({ type: FETCH_REPLIES });
		return fetch(`${API_HOST}/v1/repliesForInReplyTo`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
        inReplyToIds: inReplyToIds
      })
		})
			.then(response => {
				return response.json()
			})
			.then(json => {
				if (json.replies === undefined) {
					return;
				}
				return dispatch(fetchRepliesSuccess(json.replies.items));
			})
			.catch(error => {
				dispatch({
					type: FAILURE,
					errorMessage: "something went wrong on our side."
				});
				console.log(error);
			});
	};
};

export const fetchRepliesForInReplyToIds = (inReplyToIds) => (dispatch, getState) => {
	return dispatch(__fetchRepliesForInReplyToIdsAPI(inReplyToIds));
};

const __fetchRepliesAPI = (inReplyToId, count) => {
	return function (dispatch) {
		dispatch({ type: FETCH_REPLIES });
		return fetch(`${API_HOST}/v1/replies?inReplyTo=${inReplyToId}&count=${count}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
		})
			.then(response => {
				return response.json()
			})
			.then(json => {
				return dispatch(fetchRepliesSuccess(json.replies.items));
			})
			.catch(error => {
				dispatch({
					type: FAILURE,
					errorMessage: "something went wrong on our side."
				});
				console.log(error);
			});
	};
};

export const fetchReplies = (inReplyToId, count=10) => (dispatch, getState) => {
	return dispatch(__fetchRepliesAPI(inReplyToId, count));
};

const __fetchRepliesAttributedToAPI = (minOrder, attributedTo) => {
	return function (dispatch) {
		dispatch({ type: FETCH_REPLIES });
		return fetch(`${API_HOST}/v1/replies?attributedTo=${attributedTo}&order=${minOrder}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
		})
			.then(response => {
				return response.json()
			})
			.then(json => {
				if (json.replies === undefined) {
					return;
				}
				return dispatch(fetchRepliesSuccess(json.replies.items));
			})
			.catch(error => {
				dispatch({
					type: FAILURE,
					errorMessage: "something went wrong on our side."
				});
				console.log(error);
			});
	};
};

export const fetchRepliesAttributedTo = (actorId) => (dispatch, getState) => {
	const minOrder = getMinOrder(getState().feeds, actorId);
	return dispatch(__fetchRepliesAttributedToAPI(minOrder, actorId));
};


const __fetchRepliesWithContentAPI = (minOrder, attributedTo) => {
	return function (dispatch) {
		dispatch({ type: FETCH_REPLIES });
		return fetch(`${API_HOST}/v1/replies?isContent=true&order=${minOrder}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			},
		})
			.then(response => {
				return response.json()
			})
			.then(json => {
				if (json.replies === undefined) {
					return;
				}
				return dispatch(fetchRepliesSuccess(json.replies.items));
			})
			.catch(error => {
				dispatch({
					type: FAILURE,
					errorMessage: "something went wrong on our side."
				});
				console.log(error);
			});
	};
};

export const fetchRepliesWithContent = (actorId) => (dispatch, getState) => {
	const { isFetching } = getState().loading;
	if (isFetching === true) {
		return;
	}

	const minOrder = getMinOrderWithContent(getState().feeds, actorId);
	return dispatch(__fetchRepliesWithContentAPI(minOrder, actorId));
};



const deleteReplyAPI = (id, actorId) => {
	return function (dispatch) {
		return fetch(`${API_HOST}/v1/reply/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			}
		})
			.then(() => {
					dispatch({ type: DELETE_REPLY_SUCCESS, id });
				// Delete Reply
				// dispatch(addReplySuccess(json))
			});
	};
}

export const deleteReply = (id) => (dispatch, getState) => {
	const actorId = getState().user.id
	return dispatch(deleteReplyAPI(id, actorId))
};
