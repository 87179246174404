
export function storeGet(key) {
    return JSON.parse(localStorage.getItem(key));
}

export function storeSet(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.log("localStorage error: " + error.message);
    }
}

export function storeRemove(key) {
    return localStorage.removeItem(key);
}