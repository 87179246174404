import React, { Component } from 'react'

//const URL = 'https://media.giphy.com/media/I6pkzauJaIJSo/giphy.gif'
//const URL = 'https://web.commentaire.me/dull_rainbow.gif'
//const URL = 'https://cdn.dribbble.com/users/3251/screenshots/470914/aah.gif'
// const URL = 'https://loading.io/spinners/color-bar/lg.colorful-progress-loader.gif'
// const URL = 'https://i.ya-webdesign.com/images/transparent-bars-loading-3.gif';
const URL = 'https://thumbs.gfycat.com/LightShabbyLamprey-small.gif';

export default class extends Component {
  constructor(props) {
    super(props)
    this.style = {width: '280px'}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <div style={{textAlign: 'center', position: 'absolute', zIndex: 999999, top: '10px', left: '1%', right: '1%'}}>
        <img style={{...this.style}} src={URL} alt=''/>
      </div>
    )
  }
}
