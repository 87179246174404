import {
  ADD_OBJECT,
  FETCH_REPLIES,
  FETCH_OBJECT_SUCCESS,
  FETCH_REPLIES_SUCCESS,
  REQUEST,
  SUCCESS,
  FAILURE,
  RESET
} from "../constants/ActionTypes";

const initialState = {
  isFetching: false,
  errorMessage: ""
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPLIES:
    case ADD_OBJECT:
    case REQUEST:
      return {
        ...initialState,
        isFetching: true
      };
    case FETCH_REPLIES_SUCCESS:
    case FETCH_OBJECT_SUCCESS:
    case SUCCESS:
      return {
        ...initialState
      };
    case FAILURE:
      return {
        ...initialState,
        errorMessage: action.errorMessage
      };
    case RESET:
      return initialState
    default:
      return state;
  }
}