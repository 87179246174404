import React, { Component } from "react";
import { Jumbotron } from "react-bootstrap";

class Privacy extends Component {
	render() {
		return (
			<div>
				<Jumbotron>
					<h1>Deletion of data!</h1>

					<p>
						You can delete your post at any time. If you delete your
						post, the link and all contained images are permanently
						deleted from the server. Every post is automatically
						deleted withing 24 hours.
					</p>
					<p>
						Any files you upload can be deleted at any time,
						regardless which channel they're in.
					</p>
					<h1>Data protection!</h1>
					<p>
						ugifs is hosted in USA and subject to USA data
						protection regulation. After deletion, no information
						about your post, pictures or logs is kept, nor is it
						transferred or sold to any third-parties.
					</p>
					<p>
						In case of offences (hacking attacks, uploading of
						illegal material or other unacceptable behavior),
						utter-gifs reserves the right to record your IP address.
						This is to ensure smooth operation of the system and
						cooperation with law enforcement.
					</p>
					<h1>Cookies</h1>
					<p>
						A cookie is used to ensure functionality of the
						application and to keep track of your user account (if
						you created one).
					</p>
					<p />
				</Jumbotron>;
			</div>
		);
	}
}

export default Privacy;