import {
	FETCH_REACTIONS_SUCCESS,
} from "../constants/ActionTypes";

export const reactions = (state = {}, action) => {
	switch (action.type) {
		case FETCH_REACTIONS_SUCCESS:
			return {
				...action.reactions.items.reduce((obj, val) => {
					//let actionVal = { post: val, type: action.type };
					obj[val.id] = val;
					return obj;
				}, {})
			};
		default:
			return state;
	}
};