import React, { Component } from 'react'

export default class extends Component {

  componentDidMount = () => {
    if (window.location.href.includes('actor/verify') && window.location.href.includes('https')) {
      let url = window.location.href;
      const path = url.split(':')[1];
      window.location.href = `http:${path}`;
    }
  }

  render() {
    return (
      <div style={styles.body}>
        <br></br>
        <br></br>
        <div style={styles.center}>
          <span style={styles.neg}>ERROR 404</span>
          <p style={styles.p}>
            The page is missing or never was written. You can wait and<br />
            see if it becomes available again.  
          </p>
          Press any link to continue <span style={styles.blink}>_</span>
          <div style={styles.menu}>
            <a style={styles.a} href="/">home</a>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  body: {
    
    color:'#ffffff',
    fontFamily:'courier',
    fontSize:'12pt',
    textAlign:'center',
    //margin:'100px',
},

blink: {
    color:'yellow',
    animation: 'blinker 1s linear infinite'
},

neg: {
    background:'#fff',
    color:'#0000aa',
    padding:'2px 8px',
    fontWeight:'bold'
},

p: {
    margin:'30px 100px',
    textAlign:'left'
},

menu: {
    textAlign:'center',
    marginTop:'50px' 
},

a: {
  color: 'white'
},

    center: {
      margin: 'auto',
      width: '800px',
      background:'#0000aa',
    }
}
