import { Component } from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-128967421-1')

class Analytics extends Component {
  constructor(props) {
    super(props)

    // Initial page load - only fired once
    this.sendPageChange(props.location.pathname, props.location.search)
    //document.body.style.backgroundColor = "#021c1e";
    document.body.style.backgroundColor = "#282c34";
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname
      || this.props.location.search !== nextProps.location.search) {
      this.sendPageChange(nextProps.location.pathname, nextProps.location.search)
    }
  }

  sendPageChange(pathname, search='') {
    const page = pathname + search
    ReactGA.set({page});
    ReactGA.pageview(page);
  }

  render() {
    return null
  }
}

export default Analytics
