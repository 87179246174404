import React, { Component } from "react";

export default class Request extends Component {
    constructor(props) {
        super(props);
        this.style = { color: "red", fontSize: "1.5em" };
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <label>
                {
                    this.props.href !== '' &&  this.props.href !== undefined ?
                    <img src={this.props.href} alt={''} style={styles.postImg} /> :
                    null
                 }
                <img src={'https://twemoji.maxcdn.com/2/svg/1f450.svg'} alt={""} style={styles.img} />
                <label style={styles.text}>{this.props.text}</label>
            </label>
        );
    }
}

const styles = {
    button: {
        height: "3rem",
        fontSize: "18px",
        fontWeight: 500,
        borderRadius: "5px",
        marginRight: "10px",
        //backgroundColor: "#E3FCEF",
        backgroundColor: "white",
        color: "#006644",
        //boxShadow: "0 3px 8px rgba(0,0,0,0.175)"
    },
    img: {
        width: "50px",
        borderRadius: "5px",
        //boxShadow: "0 3px 8px rgba(0,0,0,0.175)",
        marginRight: "10px"
    },
    postImg: {
        width: "50px",
        borderRadius: "5px",
        boxShadow: "0 3px 8px rgba(0,0,0,0.175)",
        marginRight: "10px",
        float: "left"
    },
    text: {
        //marginRight: "10px"
    }
};