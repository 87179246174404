import React from "react";

const ReactionComment = ({ reaction, onSubmit }) => (
    <div style={styles.wrapper} onClick={e => onSubmit(e, reaction.href)}>
        <img src={reaction.href} style={styles.image} alt={""} />
    </div>
);

const ReactionComments = ({ reactions, onSubmit }) => (
    <div style={styles.container}>
        {reactions.map(reaction => (
            <ReactionComment
                onSubmit={onSubmit}
                reaction={reaction}
                key={reaction.id}
            />
        ))}
    </div>
);

const styles = {
    container: {
        //boxShadow: "rgba(34, 25, 25, 0.4) 0px 1px 2px",
        //borderRadius: "3px",
        //width: "300px",
        //background: "white",
        marginBottom: "5px",
        height: '300px',
        overflow: 'scroll',
        textAlign: 'center',

 

    },
    wrapper: {
        display: "inline-block",
        margin: '5px',
        border: '0.1rem solid #fff',
        //animation: 'flicker 1.5s infinite alternate',    
    },
    image: {
        width: "100px",
        height: "100px",
        boxShadow: "rgba(34, 25, 25, 0.4) 0px 1px 2px",
        borderRadius: "4px"
    }
};

export default ReactionComments;