import {
	ADD_ONLINE_ACTORS_COUNT,
	ADD_ALERT,
	CLEAR_ALERTS,
	SET_ID,
	SET_REACTIONS_LAST_ACCESSED_TIME,
	FETCH_OBJECTS,
	FETCH_OBJECTS_SUCCESS,
	ADD_NEWLY_CREATED_POST_LINK
} from "../constants/ActionTypes";

const initialState = {
	id: null,
	actorId: null, 
	onlineActors: 0,
	alerts: [],
	expiry: null,
	isOpen: false,
	isNSFW: true,
	reactionsLastAccessedTime: null,
	isFetching: false,
	newlyCreatedPostLink: null
};

export const realtime = (state = initialState, action) => {
	switch (action.type) {
		case ADD_NEWLY_CREATED_POST_LINK:
			return {
				...state,
				newlyCreatedPostLink: action.newlyCreatedPostLink
			};
		case ADD_ONLINE_ACTORS_COUNT:
			return {
				...state,
				onlineActors: action.object.count
			};
		case ADD_ALERT:
			return {
				...state,
				alerts: [
					{ text: action.text, alertType: action.alertType, autoDismiss: action.autoDismiss, href: action.href },
					...state.alerts
				]
			};
		case CLEAR_ALERTS:
			return {
				...state,
				alerts: []
			}
		case SET_ID:
			return {
				...state,
				actorId: action.actorId,
				id: action.id,
				expiry: action.expiry,
				isOpen: action.isOpen,
				isNSFW: action.isNSFW
			}
		case SET_REACTIONS_LAST_ACCESSED_TIME:
			return {
				...state,
				reactionsLastAccessedTime: new Date()
			}
		case FETCH_OBJECTS:
			return {
				...state,
				isFetching: true
			}
		case FETCH_OBJECTS_SUCCESS:
			return {
				...state,
				isFetching: false
			}
		default:
			return state;
	}
};