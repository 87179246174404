import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { addReplyContent } from "../../actions/reply.js";
import '../libs/modal1.css';

class ReplyContent extends Component {
  state = {
    text: ''
  };

  constructor(props) {
    super(props);
    this.style = {};
    if (props.style) {
      this.style = { ...this.style, ...props.style };
    }
  }

  componentDidMount = () => {
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.show) {
      this.setState({
        text: ''
      });
    }
    if (nextProps.show === true && this.props.show === false) {
      this.props.onMount();
    }
  };

  handleChange = (e) => {
    if (e.target.value.length >= 50) {
      alert('text can not be greater than 50 characters');
      return;
    }
    this.setState({
      text: e.target.value
    });
  }

  handleClose = () => {
    this.props.show = false;
  }

  render() {
    const { id, href, show, isAuthenticated } = this.props;

    if (!show) {
      return null;
    }

    const style = {
      fontSize: '40px',
      fontWeight: 'bolder',
      color: 'white',
      WebkitTextFillColor: 'yellow', /* Will override color (regardless of order) */
      WebkitTextStrokeWidth: '1px',
      WebkitTextStrokeColor: 'black',
      textShadow: '0 0 3px black'
    }

    return (
      <div className="preview static-modal">
        <Modal.Dialog style={styles.container}>
          <Modal.Body style={styles.body}>
            {/*!isAuthenticated && <Button 
            onClick={() => window.location.href='/'}
            style={styles.buttonSubmit} bsStyle={"warning"}>{'PLEASE SIGN IN TO ADD CAPTION TO GIF'}</Button> */}
            { <Button
              onClick={() => this.props.onSubmit(id, this.state.text, style)}
              style={styles.buttonSubmit}
              bsStyle={"success"}
              class="neon-h1"
            >
              SUBMIT
            </Button> }
            <Button
              onClick={() => this.props.onClose()}
              style={styles.buttonClose}
              bsStyle={"danger"}
              class="neon-h1"
            >
              CLOSE
            </Button>
            <FormGroup>
              <FormControl
                inputRef={ref => { this.text = ref; }}
                style={styles.input}
                type={"text"}
                placeholder={"text - max 50 character"}
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <div style={styles.gifMain}>
              <img src={href} style={styles.gif} />
              <div style={styles.gifText}>
                {this.state.text}
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { isAuthenticated } = state.user
  const { show, id, href } = props;

  return {
    isAuthenticated,
    show,
    href,
    id
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
    },
    onSubmit: (id, content, style) => {
      if (content.includes('http') || content.includes('rape')) {
        alert('not allowed')
        return;
      }
      dispatch(addReplyContent(id, content, style));
      props.onClose();
    },
    onClose: () => {
      props.onClose();
    },
  };
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '1.75rem auto',

  },
  wrapper: {

  },
  body: {
    padding: '1em'
  },
  buttonSubmit: {
    width: '78%',
    marginBottom: '10px',
    borderColor: 'white',
    float: 'left'
  },

  buttonClose: {
    width: '20%',
    marginBottom: '10px',
    borderColor: 'white',
    float: 'right'
  },
  input: {
    height: "3rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
  gifMain: {
    position: 'relative',
  },
  gif: {
    width: '100%',

  },
  gifText: {
    position: 'absolute',
    zIndex: 99999999,
    top: 0,
    left: '1%',
    right: '1%',
    fontSize: '30px',
    fontWeight: 'bolder',
    color: 'white',
    WebkitTextFillColor: 'yellow', /* Will override color (regardless of order) */
    WebkitTextStrokeWidth: '1px',
    WebkitTextStrokeColor: 'black'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyContent);