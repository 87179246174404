import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import './train.css'

const WEBMS = [
  'CompetentOrangeCarpenterant',
  'ChillyLeafyEmperorshrimp',
  'DetailedBaggyIndigowingedparrot',
  'SameDazzlingBighorn',
  'IncompleteScornfulHen',
  'RashBossyChihuahua',
  'SoreQuestionableLice',
  'SpectacularSecondAmurstarfish',
  'SelfreliantWeakAquaticleech',
  'BrightNeglectedDromedary',
  'ScarceSmugDobermanpinscher',
  'PointedDecisiveElephantbeetle',
  'SpiritedSpicyAnglerfish',
  'SpotlessFelineHalicore',
  'FancyThunderousGazelle',
  'ColorlessScarceDunnart',
  'UnawareEdibleArcticfox',
  'PeriodicWhiteAldabratortoise',
  'AngryWeeklyBlackpanther',
  'OfficialHugeFluke',
  'AnnualQuarterlyAmurminnow',
  'FelineInformalConey',
  'ElasticAridBoto',
  'SentimentalPerfumedArkshell',
  'OfficialHugeFluke',
  'RashTallClam',
  'ClutteredCooperativeEasteuropeanshepherd',
  'LastCautiousArrowcrab',
  'PlumpSarcasticCicada',
  'BestDisgustingAphid',
  'LavishUnfinishedAmericanalligator',
  'HollowLargeAttwatersprairiechicken',
  'FirstKindBlackpanther',
  'AbandonedSkinnyAnemone',
  'IllegalSomberIberianmidwifetoad',
  'YellowishTallGreendarnerdragonfly',
  'FlippantWellgroomedGopher',
  'KlutzyDimwittedBunn',
  'ReliableHandsomeAmericanlobster',
  'CheerfulFlimsyHammerheadbird',
  'WelldocumentedFirsthandHoneybadger',
  'GleamingHappyGaur',
  'PiercingLimitedBarnswallow',
  'EqualUnselfishBrant',
  'NarrowGiantGerenuk',
  'WindyBruisedAtlanticblackgoby',
  'EqualUnselfishBrant',
]


class Post extends Component {

  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      left: 0,
      active: false,
      isWEBM: false,
      isNSFW: [0],
      comment_index: 0,
      showReplyContentForm: false,
      webm_index: Math.floor(Math.random() * WEBMS.length),
      slideInterval: 1000
    }
  }

  onPostClick = (e) => {
    const cli = e.target.getClientRects()[0];
    const dimensions = getPinPosPercent(
      e.pageX - cli.left,
      e.pageY - cli.top - window.scrollY,
      e.target.width,
      e.target.height
    );

    this.setState({
      top: dimensions.y,
      left: dimensions.x,
      active: true
    })

    this.props.showPin(dimensions.y, dimensions.x)
  }

  onCancel = (e) => {
    this.setState({
      active: false
    })
    e.stopPropagation();
  }

  getRandomWEBM = () => {
    const gyfy_title = WEBMS[this.state.webm_index];

    return {
      'href': `https://giant.gfycat.com/${gyfy_title}.webm`
    }
  }

  __handleToggleWEBM = (e) => {
    this.setState({
      isWEBM: !this.state.isWEBM,
      isNSFW: e
    })
  }


  onImageClick = (event) => {
    console.log("CLICKED")
    const { isAuthenticated } = this.props
    const imageIndex = this._imageGallery.getCurrentIndex();

    const { id, content, original, verified } = this.images[imageIndex];

    if (content) {
      alert('Can not add text over a GIF with text on it already');
      return;
    }

    if (verified && !isAuthenticated) {
      alert('Can not add text over a GIF until authenticated. Please register/login.');
      return;
    }

    this.setState({
      showReplyContentForm: true,
      selectedReplyId: id,
      selectedReplyHref: original
    });
  }

  onPrevCommentClicked = () => {
    this.setState({
      comment_index: (this.state.comment_index - 1) % this.props.comments.length
    })
  }

  onNextCommentClicked = () => {
    if (this.state.isWEBM) {
      this.setState({
        webm_index: (this.state.webm_index + 1) % WEBMS.length
      })
      return;
    }
    this.setState({
      comment_index: (this.state.comment_index + 1) % this.props.comments.length
    })
  }

  getCurrentIndex = (i) => {
  }

  _onScreenChange = (fullScreenElement) => {
  }


  _onSlide = (index) => {
    let dec = 0
    if (this.state.slideInterval <= 100) {
      // dec = 10
    }
    else if (this.state.slideInterval <= 500) {
      // dec = 200
    }
    else if (this.state.slideInterval < 1000) {
      // dec = 500
    }
    if (index === 0) {
      if (this.state.slideInterval > 20) {
        this.setState({
          slideInterval: this.state.slideInterval - dec
        })
      } else {
        this.setState({
          slideInterval: 1000
        })
      }
    }
  }

  onReplyContentClose = () => {
    this.setState({
      showReplyContentForm: false,
      selectedReplyId: null,
      selectedReplyHref: null,
    })
  }

  customRender = (item) => {
    // customize your own slide below
    let isTiktok = false;
    if (item.original && item.original.search('mp4') > 0) {
      isTiktok = true;
    }
    if (item.original && item.original.search('webm') > 0) {
      isTiktok = true;
    }
    return (
      <div>
    { isTiktok === false && <img  className='image-gallery-image'
          src={item.original}
          title={item.originalTitle}
    />
    }
    { isTiktok === true && 
        <video className='image-gallery-image'  autoPlay>
          <source src={item.original} type="video/mp4"/>            
        </video>
  }

        {
          item.description &&
          <span className='image-gallery-description'>
            <span style={{ ...item.style, ...styles.gifTextDefault}}>
              {item.description}
            </span>
          </span>
        }
      </div>
    );
  };

  render() {
    const {
      object,
      comments,
    } = this.props

    this.images = comments.reduce((obj, o) => {
      obj.unshift({
        original: o.image.href,
        thumbnail: o.image.href,
        description: o.content,
        style: o.style,
        originalClass: 'train',
      })

      obj.unshift({
        original: object.image.href,
        thumbnail: object.image.href
      })
      return obj;
    }, []);

    const width = window.innerWidth
    let st = styles.wrapper;
    let med = styles.media;

    if (width < 400) {
      st = {
        ...st,
        width: "100%"
      }
      med = {
        ...med,
        width: "100%"
      }
    }

    return (
      <div style={styles.container} ref="elem">
        <div
          style={st}
          onClick={e => this.onPostClick(e)}
        >
        </div>
        <div style={med}>
          {
            !this.state.isWEBM &&
            <ImageGallery
              ref={i => this._imageGallery = i}
              items={this.images}
              showThumbnails={false}
              useBrowserFullscreen={true}
              showBullets={false}
              disableArrowKeys={true}
              showFullscreenButton={false}
              slideInterval={this.state.slideInterval}
              showPlayButton={true}
              autoPlay={true}
              showNav={false}
              slideDuration={10}
              getCurrentIndex={this.getCurrentIndex}
              onScreenChange={this._onScreenChange}
              onSlide={this._onSlide}
              onPlay={this._onPlay}
              renderItem={this.customRender}
              onClick={e => this.onImageClick(e)}
              lazyLoad={true}
            />
          }
        </div>
      </div>
    )

  }
}



const getPinPosPercent = (x, y, width, height) => {
  const dimensions = {
    x: Math.round(x / width * 100, 2) + "",
    y: Math.round(y / height * 100, 2) + ""
  };
  return dimensions;
};

const styles = {
  wrapper: {
    position: "relative",
    zIndex: 3,
    cursor: "pointer",
    minWidth: "150px",
    display: "inline-block",
    boxShadow: "0 1px 2px rgba(34, 25, 25, 0.4)",
    //borderRadius: "3px",
    overflow: "hidden",
    float: "left",
    width: "50%"
  },
  container: {
    margin: "auto",
    display: "inline-block",
    minWidth: "250px",
    textAlign: "center",
    //width: "70%",
    //float: "left",
    marginRight: "10px",
    width: "100%",
    //background: 'white'
  },
  media: {
    display: 'inline-block',
    width: "80%"
  },
  nsfwLabel: {
    marginRight: '10px',
    WebkitAppearance: ''
  },
  btn: {
    background: 'rebeccapurple',
    color: 'white',
    marginBottom: '10px'
  },
  gifTextDefault: {
    fontFamily: 'Russo none',
    textShadow: '5px 0 3px rgba(251, 0, 231, 0.8), 0 5px 3px rgba(0, 233, 235, 0.8), -5px 0 3px rgba(0, 242, 14, 0.8), 0 -5px 3px rgba(244, 45, 0, 0.8), 5px 0 3px rgba(59, 0, 226, 0.8)',
    color: 'rgba(255, 255, 255, 0.9)',
    /* transform: translateY(-100%); */
    color: 'rgba(255, 255, 255, 0.9)',
    /*background: 'rgba(0, 0, 0, 0.8)',*/
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    /* height: 0.5em; */
    fontSize: '2em'
  }
};

export default Post;
