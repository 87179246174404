export const ACelebs = [
    {
       name: 'Aly Raisman',
       year: 1972
    },
    {
        name: 'Amber Heard',
        year: 1972
     },
     {
        name: 'Ariel Winter',
        year: 1972
     },
];

