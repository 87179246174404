import { v4 as uuidv4 } from 'uuid';

import {
  ADD_OBJECT,
  FETCH_OBJECT,
  FETCH_OBJECTS,
  FETCH_OBJECTS_SUCCESS,
  FETCH_OBJECT_SUCCESS,
  FAILURE,
  SET_ID,
  DELETE_OBJECT_SUCCESS,
  ADD_NEWLY_CREATED_POST_LINK
} from "../constants/ActionTypes";
import { API_HOST } from "../config/Config";
import { fetchRepliesForInReplyToIds, fetchRepliesSuccess } from "./reply";
import { fetchActor } from './actor';
import { sendMessage } from "./socket";
import { getMinSequence, getMinSequenceAttributedTo } from "../utils/objects";


export const fetchObjectSuccess = object => {
  return {
    type: FETCH_OBJECT_SUCCESS,
    object
  };
};

export const deleteObjectSuccess = id => {
  return {
    type: DELETE_OBJECT_SUCCESS,
    id
  };
};

const addImageAPI = (file, name, inReplyTo, actorId, isOpen, isNSFW, identity) => {
  return function (dispatch) {
    dispatch({ type: ADD_OBJECT });
    let href;
    const fileType = file.type;
    const newFileNameId = uuidv4();
    const extension = file.name.split('.').pop();

    var currentTime = new Date();
    const currentYear = currentTime.getFullYear();
    const currentMonth = getTwoDigit(currentTime.getMonth() + 1);
    const currentDate = getTwoDigit(currentTime.getDate());
    const prefixFolder = `objects/${currentYear}/${currentMonth}/${currentDate}`;

    return fetch(`${API_HOST}/v1/object-storage/signedURL`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${identity}`
      },
      body: JSON.stringify({
        filetype: file.type,
        // filename: `temp/${name}/${newFileNameId}/${newFileNameId}.${extension}`
        filename: `${prefixFolder}/${name}/${newFileNameId}/${newFileNameId}.${extension}`
      })
    })
      .then(response => response.json())
      .then(json => {
        if (Object.keys(json).length === 0) {
          throw new Error('User is not authorized');
        }
        const url = json.url;
        const fields = json.fields;
        href = "https://" + fields.bucket + "/" + fields.key;
        href = `https://${fields.bucket}/${fields.key}`;
        href = `https://i.ugifs.com/${fields.key}`;

        const data = new FormData();
        data.append("key", fields.key);
        data.append("x-amz-algorithm", fields["X-Amz-Algorithm"]);
        data.append("x-amz-date", fields["X-Amz-Date"]);
        data.append("acl", fields.acl);
        data.append("x-amz-signature", fields["X-Amz-Signature"]);
        data.append("Cache-Control", fields["Cache-Control"]);
        data.append("Content-Type", fileType);
        data.append(
          "x-amz-storage-class",
          fields["x-amz-storage-class"]
        );
        data.append("policy", fields.Policy);
        data.append("x-amz-credential", fields["X-Amz-Credential"]);
        data.append("file", file);

        const options = {
          headers: {
            ...fields,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true
          }
        };

        return fetch(url, {
          method: "POST",
          body: data,
          options
        });
      })
      /*.catch(error => {
        console.log(error);
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      })*/
      .then(response => {
        dispatch(addObjectAPI(href, name, inReplyTo, actorId, isOpen, isNSFW));
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      });
  };
};

function get_url_extension( url ) {
	return url.split(/[#?]/)[0].split('.').pop().trim();
}


const addObjectAPI = (href, name, inReplyTo, actorId, isOpen, isNSFW) => {

  const ext = get_url_extension(href)

  let url = `${API_HOST}/v1/object`;
  if (ext === 'mp4' || ext === 'webm') {
    url = `${API_HOST}/v1/objectVideo`;
  }

  return function (dispatch) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        href,
        inReplyTo,
        isOpen,
        isNSFW,
        attributedTo: actorId,
        type: 'POST'
      })
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(fetchObjectSuccess(json.object));

        dispatch({
          type: SET_ID,
          actorId: json.object.attributedTo,
          expiry: json.object.expiry,
          id: json.object.id,
          isOpen: json.object.isOpen,
          isNSFW: json.object.isNSFW
        });
        dispatch(fetchObjectSuccess(json.object));
        dispatch(sendMessage(json.object));
        dispatch({ type: ADD_NEWLY_CREATED_POST_LINK, newlyCreatedPostLink: `/i/${json.object.id}` })
      })
      .catch(error => {
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      });
  };
};

export const addObject = (file, name, isOpen, isNSFW, inReplyTo) => (dispatch, getState) => {
  const actorId = getState().user.id
  const identity = getState().user.identity
  if (!actorId.includes('@')) {
    alert('Not allowed to upload. Please sign in');
    return;
  }

  dispatch(fetchActor(actorId));

  dispatch(addImageAPI(file, name, inReplyTo, actorId, isOpen, isNSFW, identity));
};


export const addObjectLink = (href, name) => (dispatch, getState) => {
  const actorId = getState().user.id
  const identity = getState().user.identity
  if (!actorId.includes('@')) {
    alert('Not allowed to upload. Please sign in');
    return;
  }

  dispatch(fetchActor(actorId));
  const inReplyTo = undefined

  dispatch(addObjectAPI(href, name, inReplyTo, actorId));
};

const fetchObjectAPI = id => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/object/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        if (json.items.length === 0) {
          alert('Post is not available. Either its deleted or was never present.');
        }
        dispatch(fetchRepliesSuccess(json.replies.items));
        return json.items.map(item => {
          dispatch({
            type: SET_ID,
            id: id,
            actorId: item.object.attributedTo,
            expiry: item.object.expiry,
            isOpen: item.object.isOpen,
            isNSFW: item.object.isNSFW
          });

          return dispatch(fetchObjectSuccess(item.object));
        });
      })
      .catch(error => {
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      });
  };
};

export const fetchObject = id => (dispatch, getState) => {
  return dispatch(fetchObjectAPI(id));
};

const __fetchObjectsAPI = (minSequence, count = 100, name = '') => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECTS });

    let url;
    if (name === 'all') {
      url = `${API_HOST}/v1/objects?type=POST&minSequence=${minSequence}&maxResults=${count}&active=true&verified=true`;
    } else {
      url = `${API_HOST}/v1/objects?type=POST&minSequence=${minSequence}&maxResults=${count}&name=${name}&active=true`;
    }
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: FETCH_OBJECTS_SUCCESS });
        json.items.map((item) => {
          item.object['section'] = 'all'
          return dispatch(fetchObjectSuccess(item.object))
        })

        const objectIds = json.items.map((item) => {
          return item.object.id;
        });
        return dispatch(fetchRepliesForInReplyToIds(objectIds));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}


const __fetchUnverifiedObjectsAPI = (minSequence, count = 100, name = '') => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECTS });

    let url = `${API_HOST}/v1/objects?type=POST&minSequence=${minSequence}&maxResults=${count}&active=true&verified=false`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: FETCH_OBJECTS_SUCCESS });
        json.items.map((item) => {
          item.object['section'] = 'all'
          return dispatch(fetchObjectSuccess(item.object))
        })

        const objectIds = json.items.map((item) => {
          return item.object.id;
        });
        return dispatch(fetchRepliesForInReplyToIds(objectIds));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchObjects = (name) => (dispatch, getState) => {
  const count = 10
  if (name === undefined) {
    name = 'all'
  }

  const minSequence = getMinSequence(getState().objects, name);
  return dispatch(__fetchObjectsAPI(minSequence, count, name));
};

export const fetchUnverifiedObjects = () => (dispatch, getState) => {
  const count = 10
  let name = undefined;
  const minSequence = getMinSequence(getState().objects, name);
  return dispatch(__fetchUnverifiedObjectsAPI(minSequence, count, name));
};


const __deleteObjectAPI = (id, attributedTo, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/object/${id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        active: false,
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch(sendMessage({ type: "delete", id }));
        dispatch({ type: DELETE_OBJECT_SUCCESS, id });
        //history.push('/')
      })
      .catch(error => {
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      });
  };
};

export const deleteObject = (id, attributedTo) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__deleteObjectAPI(id, attributedTo, actorId));
};

export const onLikeObject = (id, href) => (dispatch, getState) => {
  dispatch(sendMessage({ type: 'like', id, href }));
};

export const onRequestForMore = (id, href) => (dispatch, getState) => {
  dispatch(sendMessage({ type: 'request', id, href }));
};

const incrViewsAPI = id => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/object/${id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        incrViews: true,
      })
    })
      .then(response => {
        return response.json()
      })
      .then(json => {

      })
      .catch(error => {
        dispatch({
          type: FAILURE,
          errorMessage: "something went wrong on our side."
        });
        console.log(error);
      });
  };
};

export const incrViews = pid => (dispatch, getState) => {
  return dispatch(incrViewsAPI(pid));
};


const __fetchObjectsAttributedToAPI = (minSequence, count = 100, attributedTo) => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECTS });
    const url = `${API_HOST}/v1/objects?type=POST&minSequence=${minSequence}&maxResults=${count}&attributedTo=${attributedTo}&active=true`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: FETCH_OBJECTS_SUCCESS });
        json.items.map((item) => {
          item.object['section'] = 'all'
          return dispatch(fetchObjectSuccess(item.object))
        })

        const objectIds = json.items.map((item) => {
          return item.object.id;
        });
        return dispatch(fetchRepliesForInReplyToIds(objectIds));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchObjectsAttributedTo = (attributedTo) => (dispatch, getState) => {
  const count = 20
  const minSequence = getMinSequenceAttributedTo(getState().objects, attributedTo);
  return dispatch(__fetchObjectsAttributedToAPI(minSequence, count, attributedTo));
};

const addFeedbackObjectAPI = (content, attributedTo) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/object`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        content,
        name: 'feedback',
        type: 'FEEDBACK',
        attributedTo,
      })
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        window.location.reload();
        // dispatch(fetchObjectSuccess(json.object));
      });
  };
};

export const addFeedbackObject = (content) => (dispatch, getState) => {
  const actorId = getState().user.id
  dispatch(addFeedbackObjectAPI(content, actorId));
};



const __fetchFeedbackObjectsAPI = () => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECTS });

    const url = `${API_HOST}/v1/objects?type=FEEDBACK`;
  
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: FETCH_OBJECTS_SUCCESS });
        json.items.map((item) => {
          return dispatch(fetchObjectSuccess(item.object))
        })
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchFeedbackObjects = () => (dispatch, getState) => {
	return dispatch(__fetchFeedbackObjectsAPI());
};

function getTwoDigit(n) {
  return n < 10 ? '0' + n : '' + n;
}