import { connect } from "react-redux";
import React, { Component } from "react";

import PostHolder from "../components/train/PostHolder";

import { fetchObject } from "../actions/object.js";
import { forceClose } from "../actions/publish";
import { getReplies } from "../utils/replies";
import { resetAlerts } from "../actions/publish";

import Ad from "../components/operations/ChaturbateAd";
import Modal1 from "../components/libs/Modal";

import LikeNotification from "../components/notifications/Like";
import ImageNotification from "../components/notifications/Image";
import GIFNotification from "../components/notifications/GIF";
import RequestNotification from "../components/notifications/Request";

class TrainContainer extends Component {
	state = {
		showReactionForm: false
	};

	componentDidMount = () => {
		this.props.onMount();
	};

	componentWillUnmount = () => {
		this.props.onUnMount();
	};

	componentWillReceiveProps = nextProps => {
		if (this.props.alerts.length !== nextProps.alerts.length) {
			this.props.flushAlerts();
			nextProps.alerts.map(alert => {
				let node;
				if (alert.alertType === "like") {
					node = (
						<LikeNotification text={alert.text} href={alert.href} />
					);
				} else if (alert.alertType === "request") {
					node = (
						<RequestNotification
							text={alert.text}
							href={alert.href}
						/>
					);
				} else if (alert.alertType === "post") {
					node = (
						<ImageNotification
							text={alert.text}
							href={alert.href}
						/>
					);
				} else if (alert.alertType === "comment") {
					node = (
						<GIFNotification text={alert.text} href={alert.href} />
					);
				} else {
					node = `"${alert.text}"`;
				}

				return this.props.toastManager.add(node, {
					appearance: "success",
					autoDismiss: alert.autoDismiss
				});
			});
		}
	};

	componentWillReceiveProps_bak = nextProps => {
		if (this.props.alerts.length !== nextProps.alerts.length) {
			this.props.flushAlerts();
			nextProps.alerts.map(alert => {
				return this.props.toastManager.add(`"${alert.text}"`, {
					appearance: "success",
					autoDismiss: alert.autoDismiss
				});
			});
		}
	};

	render() {
		const { pid, post, isPreview } = this.props;

		return (
			<div>
				<PostHolder id={post.id} post={post} />
				{
					isPreview === false && 
					<Ad />
				}
				<Modal1 pid={pid} show={this.state.showReactionForm} allowed={post.verified} isNSFW={post.isNSFW} />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { toastManager } = props;
	let { pid } = props;

	let isPreview = true;

	if (pid === undefined) {
		pid = props.match.params.pid;
		isPreview = false
	}

	let { alerts, actorId, isOpen } = state.realtime;
	const userId = state.user.id;

	const post = state.objects[pid] || {};
	const attachedPosts = getReplies(state.objects, pid);

	return {
		pid,
		post,
		attachedPosts,
		alerts,
		toastManager,
		isOpen,
		isPreview,
		isAdmin: userId === actorId,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onMount: () => {
			let { pid } = props;
			if (pid === undefined) {
				pid = props.match.params.pid;

				dispatch(fetchObject(pid))
			}
		},
		flushAlerts: () => {
			dispatch(resetAlerts());
		},
		onUnMount: () => {
			dispatch(forceClose());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainContainer);