import React, { Component } from "react";
import { Jumbotron } from "react-bootstrap";

class About extends Component {
	render() {
		return (
			<div>
				<br></br>
				<br></br>
				<Jumbotron style={{textAlign: 'center'}}>
					<h1>How does it work?</h1>
					<div>
					 If you click on Image, you can add a GIF to it.
					</div>
					<div>
					 If you click on GIF, you can add a TEXT to it.
					</div>
					<br></br>
					<h2>What is feed</h2>
					<p>
						All the GIFS with TEXT over it added by other USERS
					</p>
					<br></br>
					<h2>Why don't my posts appear on the main page</h2>
					<div>
					 Only verified posts appear on the main page.
					</div>
					<div>
					 The posts should be of the celebrity.
					</div>
					<div>
					 Non-celeberity posts will be deleted by the admin.
					</div>
					<br></br>
					<h2>Request for a feature</h2>
					<div>
					 Please add it in the feedback section
					</div>
					<br></br>
					<h2>How to submit GIF</h2>
					<div>
					 Use the link <br/>
					 <a target='_blank' href='/submit/asian' >https://ugifs.com/submit/asian</a> to submit asian GIFS <br/>
					 <a target='_blank' href='/submit/ebony' >https://ugifs.com/submit/ebony</a> to submit black GIFS <br/><br/>
					 Use the correct URL to submit GIF based on the category https://ugifs.com/submit/[CATEGORY-NAME]
					</div>
				</Jumbotron>;
			</div>
		);
	}
}

export default About;