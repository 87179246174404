export const getUnsafeReactions = (state) => {
	if (state === undefined) {
		return []
	}

	let reactions = Object.keys(state).reduce((obj, id) => {
		if (state[id].type === 'Reaction') {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return reactions;
};


export const getSafeReactions = (state) => {
	if (state === undefined) {
		return []
	}

	let reactions = Object.keys(state).reduce((obj, id) => {
		if (state[id].type === 'safe') {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return reactions;
};

export const getHReactions = (state) => {
	if (state === undefined) {
		return []
	}

	let reactions = Object.keys(state).reduce((obj, id) => {
		if (state[id].type === 'HReaction') {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return reactions;
};