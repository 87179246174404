import React from "react";

import { Provider } from "react-redux";
import { Switch, Route } from "react-router";
import { Router } from "react-router-dom";
import history from "./history";

import JuxtaPostWithToasts from "../containers/JuxtaPostWithToastsContainer"
import EmailVerification from "../containers/EmailVerificationContainer"
import ContactModalContainer from "../containers/ContactModalContainer"
import NamesModalContainer from "../containers/NamesModalContainer"


import Privacy from "../components/operations/Privacy"
import About from "../components/operations/About"
import News from "../components/operations/News"
import Moderate from "../components/operations/Moderate"
import SectionWithToasts from "../components/section/SectionWithToasts"
import Feed from "../components/feed"
import Submission from "../components/submission"
import Unverified from "../components/unverified"
import ModerateWebM from "../components/operations/ModerateWebM"
import ModerateName from "../components/operations/ModerateName"
import ReactionAdmin from "../components/reaction-admin/ReactionAdmin"
import NoMatch from "../components/libs/NoMatch"


import Reactions from "../components/operations/Reactions"

import Analytics from "./Analytics";
import Realtime from "./Realtime"
import UserLoader from "./UserLoader";

import {
  JuxtaPostLayout,
  AdminLayout,
  SectionLayout,
  NoMatchLayout,
  CenterLayout
} from "./routelayout";

const Root = ({ store }) => (
  <Provider store={store}>
    <Router history={history}>
      <div>
        <Route path="/" component={Analytics} />
        <Route path="/" component={Realtime} />
        <Route path="/" component={UserLoader} />
        <Switch>
          <SectionLayout exact path="/" component={SectionWithToasts} />
          <SectionLayout path="/privacy" component={Privacy} />
          <SectionLayout path="/about" component={About} />
          <SectionLayout path="/feedback" component={ContactModalContainer} />
          <SectionLayout path="/celebs" component={NamesModalContainer} />
          <SectionLayout path="/news" component={News} />
          <SectionLayout path="/verification/:verification_id" component={EmailVerification} />
          <SectionLayout path="/s/:name" component={SectionWithToasts} />
          <CenterLayout exact path="/unverified" component={Unverified} />
          <CenterLayout exact path="/@/:actorId" component={Submission} />
          <CenterLayout exact path="/feed" component={Feed} />
          <AdminLayout path="/checkx" component={Moderate} />
          <AdminLayout path="/submit/:race" component={ModerateWebM} />
          <AdminLayout path="/admin" component={ReactionAdmin} />
          <AdminLayout path="/name" component={ModerateName} />
          <AdminLayout path="/reactions" component={Reactions} />
          <JuxtaPostLayout path="/i/:pid" component={JuxtaPostWithToasts} />
          <NoMatchLayout component={NoMatch} />
        </Switch>
      </div>
    </Router>
  </Provider>
);

export default Root;