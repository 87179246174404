import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'

export default class extends Component {
  constructor(props) {
    super(props)
    this.style = { color: 'red' }
    //this.style = {}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <Alert style={this.style} bsStyle="danger">
        {this.props.children}
      </Alert>
    )
  }
}
