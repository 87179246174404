import React, { Component } from "react";
import { Link } from "react-router-dom";

import Image from "../post/PostImage";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './Reaction.css';
import ReplyContent from "./ReplyContent";


class Post extends Component {

  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      left: 0,
      active: false,
      comment_index: 0,
      showReplyContentForm: false,
      selectedReplyId: null,
      selectedReplyHref: null,
    }
  }

  onPostClick = (e) => {
    this.setState({
      top: 0,
      left: 0,
      active: true
    });
    this.props.showPin(0, 0);
    /*const cli = e.target.getClientRects()[0];
    const dimensions = getPinPosPercent(
      e.pageX - cli.left,
      e.pageY - cli.top - window.scrollY,
      e.target.width,
      e.target.height
    );

    this.setState({
      top: dimensions.y,
      left: dimensions.x,
      active: true
    })

    this.props.showPin(dimensions.y, dimensions.x)*/
  }

  onCancel = (e) => {
    this.setState({
      active: false
    })
    e.stopPropagation();
  }

  onPrevCommentClicked = () => {
    this.setState({
      comment_index: (this.state.comment_index - 1) % this.props.comments.length
    })
  }

  onNextCommentClicked = () => {
    this.setState({
      comment_index: (this.state.comment_index + 1) % this.props.comments.length
    })
  }

  onImageClick = (event) => {
    const { isAuthenticated } = this.props
    const imageIndex = this._imageGallery.getCurrentIndex();

    const { id, content, original, verified } = this.images[imageIndex];

    if (content) {
      alert('Can not add text over a GIF with text on it already');
      return;
    }

    if (verified && !isAuthenticated) {
      alert('Can not add text over a GIF until authenticated. Please register/login.');
      return;
    }

    this.setState({
      showReplyContentForm: true,
      selectedReplyId: id,
      selectedReplyHref: original
    });
  }

  onReplyContentClose = () => {
    this.setState({
      showReplyContentForm: false,
      selectedReplyId: null,
      selectedReplyHref: null,
    })
  }

  customRender = (item) => {
    // customize your own slide below
    const {
      onDeleteReaction,
      isAdmin
    } = this.props
    return (
      <div className='image-gallery-image' style={{ cursor: 'pointer' }}>
        <img
          src={item.original}
          title={item.originalTitle}
        />

        {
          item.description &&
          <span className='image-gallery-description'>
            <span style={{...item.style, ...styles.gifTextDefault}}>
              {item.description}
            </span>
          </span>
        }
        {isAdmin && <div onClick={(e) => { e.stopPropagation(); onDeleteReaction(item)} } style={styles.deleteReaction}>
          X
			</div>}
      </div>
    );
  };

  onDelete = (item) => {
    
  }

  render() {
    const {
      object,
      comments,
      isAdmin,
      isAuthenticated,
      onDeleteReaction
    } = this.props

    this.images = comments.reduce((obj, o) => {
      obj.unshift({
        id: o.id,
        content: o.content,
        original: o.image.href,
        thumbnail: o.image.href,
        originalClass: 'reaction',
        description: o.content,
        style: o.style,
        verified: object.verified
      })
      return obj;
    }, []);

    /*images = [];

    images.push({
      original: `https://www.reactiongifs.com/r/ahwg.gif`,
      originalClass: 'reaction',
      description: 'Joker is laughing',
    });

    images.push({
      original: `https://www.reactiongifs.com/r/gc.gif`,
      originalClass: 'reaction',
      description: 'Senfied running',
    });*/

    const width = window.innerWidth
    let st = styles.wrapper;
    let med = styles.media;

    if (width < 400) {
      st = {
        ...st,
        width: "100%"
      }
      med = {
        ...med,
        width: "100%"
      }
    }

    let isTiktok = false;
    if (object.image && object.image.href && object.image.href.search('mp4') > 0) {
      isTiktok = true;
    }
    if (object.image && object.image.href && object.image.href.search('webm') > 0) {
      isTiktok = true;
    }
    return (
      <div style={styles.container} ref="elem">
        <ReplyContent
          show={this.state.showReplyContentForm}
          id={this.state.selectedReplyId}
          href={this.state.selectedReplyHref}
          onClose={this.onReplyContentClose}
        />
        <div
          style={st}
          onClick={e => this.onPostClick(e)}
        >
          {/*<Image
            image_url={object.image && object.image.href}
          />*/}

          { isTiktok === true && 
          <video controls autoPlay loop>
            <source src={object.image && object.image.href} type="video/mp4"/>            
          </video>
          }
          {
            isTiktok === false && <Image
            image_url={object.image && object.image.href}
          />
          }
        </div>
        <div style={med}>
          <ImageGallery
            ref={i => this._imageGallery = i}
            items={this.images}
            showThumbnails={false}
            useBrowserFullscreen={true}
            showBullets={true}
            disableArrowKeys={false}
            slideDuration={1}
            showFullscreenButton={false}
            slideInterval={2000}
            renderItem={this.customRender}
            onClick={e => this.onImageClick(e)}
            lazyLoad={true}
          />
          <br></br>
          <div style={{
            textAlign: 'left',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontWeight: 200,
            fontSize: '10px',
            color: 'silver'
          }}>

            <span>
              If you click on Image, you can add a GIF to it.
              If you click on GIF, you can add a TEXT to it.
            For more information <Link to='/about'>about</Link>
            </span>
          </div>
        </div>
      </div>
    )

  }
}



const getPinPosPercent = (x, y, width, height) => {
  const dimensions = {
    x: Math.round(x / width * 100, 2) + "",
    y: Math.round(y / height * 100, 2) + ""
  };
  return dimensions;
};

const styles = {
  wrapper: {
    position: "relative",
    zIndex: 3,
    cursor: "pointer",
    minWidth: "150px",
    display: "inline-block",
    boxShadow: "0 1px 2px rgba(34, 25, 25, 0.4)",
    borderRadius: "3px",
    overflow: "hidden",
    float: "left",
    width: "50%"
  },
  container: {
    margin: "auto",
    display: "inline-block",
    minWidth: "250px",
    textAlign: "center",
    //width: "70%",
    //float: "left",
    marginRight: "10px",
    width: "100%"
  },
  media: {
    display: 'inline-block',
    width: "50%"
  },
  nsfwLabel: {
    marginRight: '10px',
    WebkitAppearance: ''
  },
  btn: {
    background: 'rebeccapurple',
    color: 'white',
    marginBottom: '10px'
  },
  deleteReaction: {
    cursor: 'pointer',
    display: 'inline-block',
    padding: '10px',
    position: 'absolute',
    background: '#282c34',
    color: 'white',
    right: 0
  },
  gifTextDefault: {
    // fontFamily: 'Russo none',
    textShadow: '5px 0 3px rgba(251, 0, 231, 0.8), 0 5px 3px rgba(0, 233, 235, 0.8), -5px 0 3px rgba(0, 242, 14, 0.8), 0 -5px 3px rgba(244, 45, 0, 0.8), 5px 0 3px rgba(59, 0, 226, 0.8)',
    color: 'rgba(255, 255, 255, 0.9)',
    color: 'rgba(255, 255, 255, 0.9)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    // fontSize: '2em',
    fontWeight: 'bolder'
  }
};

export default Post;