import React, { Component } from 'react'
import Icon from 'react-icons/lib/fa/external-link'

const styles = {
	icon: {
		//opacity: '0.4'
	}
}

export default class LinkIcon extends Component {
  constructor(props) {
    super(props)
    this.style = {}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <Icon style={styles.icon}/>
    )
  }
}
