import React, { Component } from "react";

export default class GIF extends Component {
    constructor(props) {
        super(props);
        this.style = {color: 'red'};
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }


    render() {
        return (
                <label>

                <img alt={""} style={styles.postImg} src={this.props.href} />
                                 <img 
                    alt={''} 
                    style={styles.img} 
                    src={'http://funsubstance.com/uploads/gif/215/215926.gif'}
                    />
                 {this.props.text}
                 </label>
        );
    }
}

const styles = {
    img: {
        width: '30px',
        borderRadius: '5px',
    },
    postImg: {
        width: "50px",
        borderRadius: "5px",
        boxShadow: "0 3px 8px rgba(0,0,0,0.175)",
        marginRight: "10px"
    },
};