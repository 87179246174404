import {
	FETCH_OBJECT_SUCCESS,
} from "../constants/ActionTypes";

export const object = (state, action) => {
	switch (action.type) {
		case FETCH_OBJECT_SUCCESS:
			return {
				...state,
				...action.object
			};
		default:
			return state;
	}
};