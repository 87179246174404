export const getAllObjects = (state, verified=false, name='all') => {
	if (state === undefined) {
		return []
	}

	let objects = []

	if (name === 'all') {
		objects = Object.keys(state).reduce((obj, id) => {
			if (state[id].type === 'POST' && 
				state[id].verified === verified) {
				obj.push(state[id]);
				return obj;
			}
			return obj;
		}, []);
	} else {
		objects = Object.keys(state).reduce((obj, id) => {
			if (state[id].type === 'POST' && 
				state[id].name === name && state[id].verified === verified) {
				obj.push(state[id]);
				return obj;
			}
			if (state[id].type === 'POST' && 
				state[id].name === name && state[id].name === 'tiktok') {
				obj.push(state[id]);
				return obj;
			}

			return obj;
		}, []);
	}

	let sorted_objects = objects.sort(function(a, b) {
		if (a.sequence < b.sequence) return 1;
		if (a.sequence > b.sequence) return -1;
		return 0;
	});

	return sorted_objects;
};



export const getMinSequence = (state, name='all') => {
	if (state === undefined) {
		return []
	}

	let minSequence = 10000000000000;

	for (var id in state) {
		if (name === "all") {
			if (state[id].type === 'POST' && 
			// state[id].inReplyTo === '' &&
			state[id].section === name
			) {
				minSequence = Math.min(minSequence, state[id].sequence);
			}
		} else {
			if (state[id].type === 'POST' && 
			// state[id].inReplyTo === '' &&
			state[id].name === name
			) {
				minSequence = Math.min(minSequence, state[id].sequence);
				// console.log(minSequence);
			}
		}

	}

	return minSequence
};



export const getAllObjectsAttributedTo = (state, attributedTo) => {
	if (state === undefined) {
		return []
	}

	let objects = []

		objects = Object.keys(state).reduce((obj, id) => {
			if (state[id].type === 'POST' && 
				state[id].attributedTo === attributedTo) {
				obj.push(state[id]);
				return obj;
			}
			return obj;
		}, []);

	let sorted_objects = objects.sort(function(a, b) {
		if (a.sequence < b.sequence) return 1;
		if (a.sequence > b.sequence) return -1;
		return 0;
	});

	return sorted_objects;
};


export const getMinSequenceAttributedTo = (state, attributedTo) => {
	if (state === undefined) {
		return []
	}

	let minSequence = 10000000000000;

	for (var id in state) {
		if (state[id].type === 'POST' && 
			state[id].attributedTo === attributedTo
			) {
				minSequence = Math.min(minSequence, state[id].sequence);
			}
		}

	return minSequence
};


export const getFeedbackObjects = (state) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].type === 'FEEDBACK') {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_replies = replies.sort(function(a, b) {
		if (a.sequence > b.sequence) return -1;
		if (a.sequence < b.sequence) return 1;
		return 0;
	});

	return sorted_replies;
};