export const getAllUnverfiedObjects = (state) => {
	if (state === undefined) {
		return []
	}

	let objects = Object.keys(state).reduce((obj, id) => {
		if (state[id].type === 'POST' && state[id].verified === false) {

			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_objects = objects.sort(function(a, b) {

		const aDate = new Date(a.published);
		const bDate = new Date(b.published);

		if (aDate < bDate) return 1;
		if (aDate > bDate) return -1;
		return 0;
	});

	return sorted_objects;
};