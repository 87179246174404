import React, { Component } from "react";
import { connect } from "react-redux";

import LogoIcon from "../icons/LogoIcon";
import Button from "../libs/Button";
import AdBlock from "../operations/AdBlock"
import AuthModalContainer from "../../containers/AuthModalContainer"
import AdBlockModalContainer from "../../containers/AdBlockModalContainer"

import { toggleUNSAFE, logout } from "../../actions/actor";

class CenterHeader extends Component {
  state = {
    activeTab: "login",
    show: false,
    unsafe: false,
    showContact: false,
    showNames: false,
    showVerified: false,
  };

  displayLogo() {
    const { url } = this.props;

    return (
      <LogoIcon name={url} />
    )
  }

  displayTitle() {
    const { url } = this.props;

    let title = 'UGIFS';
    if (url === 'unverified') {
      title = 'UNVERIFIED POSTS'
    } else {
      title = 'YOUR SUBMISSION';
    }

    return (
      <span style={{ color: 'deeppink', letterSpacing: '10px', fontWeight: 'bolder' }}>{title}</span>
    )
  }

  render() {
    const { actorId, isAuthenticated, url, onLogout, gold } = this.props;
    const { show, activeTab } = this.state;

    return (
      <header style={styles.container}>
        <AdBlock>
          <AdBlockModalContainer />
        </AdBlock>
        <div style={styles.tags}>
          <Button style={styles.button} onClick={() => window.location.href = `/`}>home</Button>
          &nbsp;&nbsp;
          <Button style={styles.button} onClick={() => window.location.href = `/@/${actorId}`}>@</Button>
          {/*&nbsp;&nbsp;
          <Button style={styles.button} onClick={() =>  window.location.href=`/${actorId}`}>GJ</Button>*/}
        </div>
        <div className="header-icon">
          {this.displayLogo()}
          {/*<LogoIcon name={`Submission`} />*/}
        </div>
        <div style={styles.auth}>
          {
            !isAuthenticated && <Button bsStyle={'primary'} onClick={() => this.handleLogin()} style={styles.login}>signup / login</Button>
          }
          &nbsp;&nbsp;
          {
            isAuthenticated && <Button onClick={() => onLogout()} style={styles.logout}>logout</Button>
          }
        </div>

        <div style={styles.auth}>
          {
            gold && <Button style={{ ...styles.button, color: '#FFD700', background: 'black'}}>GOLD USER</Button>
          }
        </div>
        {
          show && <AuthModalContainer show={show} activeTab={activeTab} handleCloseView={this.handleCloseView} />
        }
        { this.displayTitle() }
      </header>
    );
  }

  handleLogin = () => {
    this.setState({
      show: true,
      activeTab: "login"
    })
  }

  handleRegister = () => {
    this.setState({
      show: true,
      activeTab: "register"
    })
  }

  handleCloseView = () => {
    this.setState({
      show: false
    })
  }

  handleCloseNameView = () => {
    this.setState({
      showNames: false
    })
  }

  handleCloseVerifiedView = () => {
    this.setState({
      showVerified: false
    })
  }

  toggleUnsafe = () => {
    this.setState({
      unsafe: !this.state.unsafe
    })
    this.props.toggleUnsafe()
  }

  showContact = () => {
    this.setState({
      showContact: true
    })
  }

  handleCloseContact = () => {
    this.setState({
      showContact: false
    })
  }

  showNames = () => {
    this.setState({
      showNames: true
    })
  }

  showVerified = () => {
    this.setState({
      showVerified: true
    })
  }

  handleJournal = (id) => {
    window.location.href = `/${id}`;
  }

}

const mapStateToProps = (state, props) => {
  const { id, isAuthenticated, gold } = state.user
  let { url } = props.match;

  if (url === '/unverified') {
    url = 'unverified'
  }

  return {
    id,
    actorId: id,
    isAuthenticated,
    url,
    gold,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLogout: () => {
      dispatch(logout())
    },
    toggleUnsafe: () => {
      dispatch(toggleUNSAFE())
    }
  };
};

const styles = {
  container: {
    textAlign: "center",
    position: "relative"
  },
  auth: {
    position: "absolute",
    top: '0px',
    right: '0px',
    margin: '10px'
  },
  tags: {
    position: "absolute",
    margin: '10px'
  },
  signup: {
  },
  id: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    fontStyle: 'italic',
    marginRight: '10px',
    cursor: 'pointer'
  },
  login: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    fontStyle: 'italic',
    border: '1px solid'
  },
  logout: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    border: '1px solid white',
  },
  unsetSafe: {
    background: "white",
    marginRight: "10px"
  },
  contact: {
  },
  button: {
    background: '#282c34',
    color: 'white',
    border: '1px solid white',
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CenterHeader);