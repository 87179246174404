import React, { Component } from "react";
import { connect } from "react-redux";

import { email_verification } from "../actions/actor"

class EmailVerificationContainer extends Component {

    componentDidMount = () => {
        this.props.onMount();
    };

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onMount: () => {
            dispatch(email_verification(props.match.params.verification_id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationContainer);