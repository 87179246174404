import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import Button from "../libs/Button";

import { passReaction, failReaction } from "../../actions/moderate";

import { getUnsafeReactions } from "../../utils/reactions";
import { fetchReactions } from "../../actions/moderate";

const ModerateItem = ({ id, image, race, onPass, onFail }) => (
    <tr>
        <td>
            <img alt={""} style={styles.image} src={image && image.href} />
        </td>
        <td style={{color:'white'}}>
            {race}
        </td>
        <td>
            <Button
                bsStyle="success"
                onClick={() => onPass(id)}
                style={styles.button}
            >
                &#x2713;
            </Button>
        </td>
        <td>
            <Button
                bsStyle="danger"
                onClick={() => onFail(id)}
                style={styles.button}
            >
                &#x2717;
            </Button>
        </td>
    </tr>
);

class Reactions extends Component {
    componentDidMount = () => {
        this.props.onMount();
    };

    render() {
        const { reactions, onPass, onFail } = this.props;
        return (
            <Table striped bordered condensed hover>
                <thead>
                </thead>
                <tbody>
                    {reactions.map(reaction => (
                        <ModerateItem 
                            id={reaction.id} 
                            key={reaction.id}
                            race={reaction.race}
                            image={reaction}
                            onPass={onPass}
                            onFail={onFail}
                        />
                    ))}
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = (state, props) => {
    const reactions = getUnsafeReactions(state.objects);

    return {
        reactions
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onMount: () => {
            dispatch(fetchReactions());
        },
        onPass: (id) => {
            dispatch(passReaction(id));
        },
        onFail: id => {
            dispatch(failReaction(id));
        }
    };
};

const styles = {
    container: {},
    image: {
        width: "200px",
        cursor: "pointer"
    },
    button: {
        height: "100px",
        fontSize: "2em"
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Reactions);