export const KCelebs = [
    {
       name: 'Karlie Kloss',
       year: 1972
    },
    {
        name: 'Kim Kardashian',
        year: 1972
     },
     {
        name: 'Kylie Jenner',
        year: 1972
     },
];