import React, { Component } from "react";

export default class Image extends Component {
    constructor(props) {
        super(props);
        this.style = { color: "red" };
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <label>
                <img alt={""} style={styles.img} src={this.props.href} />
                <label style={styles.text}>{this.props.text}</label>
            </label>
        );
    }
}

const styles = {
    img: {
        width: "50px",
        borderRadius: "5px",
        boxShadow: "0 3px 8px rgba(0,0,0,0.175)",
        marginRight: "10px"
    },
    text: {
        marginRight: "10px"
    }
};