
import {
	FETCH_REPLIES_SUCCESS,
	ADD_REPLY_SUCCESS,
} from "../constants/ActionTypes";

import { reply } from "./reply";


export const feeds = (state = {}, action) => {
	switch (action.type) {
		case FETCH_REPLIES_SUCCESS:
			return {
				...state,
				...action.replies.reduce((obj, val) => {
					let actionVal = { reply: val, type: action.type };
					obj[val.id] = reply(
						state[val.id] || {},
						actionVal
					);
					return obj;
				}, {})
			};
		case ADD_REPLY_SUCCESS:
			return {
				...state,
				[action.id]: reply(state[action.id], action)
			};
		default:
			return state;
	}
};