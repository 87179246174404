import {
    FETCH_NAMES,
    FETCH_OBJECT_SUCCESS,
    FAILURE,
} from "../constants/ActionTypes";

import { API_HOST } from "../config/Config";


export const fetchObjectSuccess = object => {
    return {
        type: FETCH_OBJECT_SUCCESS,
        object
    };
};

const __fetchNamesAPI = () => {
    return function(dispatch) {
        dispatch({type: FETCH_NAMES});
        return fetch(`${API_HOST}/v1/objects?type=NAME&imageType=IMAGE&maxResults=200`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(response => response.json())
        .then(json => {
            return json.items.map((item) => {
                return dispatch(fetchObjectSuccess(item.object))
            });
        })
        .catch(error => {
            dispatch({type: FAILURE, errorMessage:'something went wrong on our side.'})
            console.log(error)
        })
    };
}

export const fetchNames = () => (dispatch, getState) => {
    /*const { isNSFW } = getState().user;
    if (name === undefined) {
        name = 'all'
    }*/

    return dispatch(__fetchNamesAPI());
};