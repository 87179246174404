import { Component } from "react";
import { connect } from "react-redux";

//import { loadUser } from "../utils/actor";

class UserLoader extends Component {
  constructor(props) {
    super(props);

    // Initial page load - only fired once
    this.props.onMount();
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {

  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      //dispatch(loadUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLoader);