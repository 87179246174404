import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { FormGroup } from "react-bootstrap";
import { RESET } from "../constants/ActionTypes";

import {
	ButtonToolbar,
	Modal
} from "react-bootstrap";

import { addObject } from "../actions/object.js";

import Alert from "../components/libs/Alert";
import Info from "../components/libs/Info";
import Warning from "../components/libs/Warning";
import Loading from "../components/libs/Loading";
import Button from "../components/libs/Button";

import AutoSuggestContainer from "./AutoSuggestContainer";


class Confirm extends Component {
	state = {
		name: '',
		isNSFW: [1],
		previewSrc: null
	}

	componentDidMount = () => {
		this.__loadPreview(this.props.file)
	}


	__handleNSFWChange = (e) => {
		this.setState({ isNSFW: e });
	}

	__handleNameChange = (name) => {
		this.setState({ name })
	}

	__loadPreview = (f) => {
		var reader = new FileReader();

		reader.onload = (function (theFile) {
			return function (e) {
				this.setState({
					previewSrc: e.target.result
				})
			};
		})(f).bind(this);

		// Read in the image file as a data URL.
		reader.readAsDataURL(f);
	}

	render() {
		const {
			onSubmit, onCancelConfirm
		} = this.props;
		const { isNSFW, previewSrc } = this.state

		return (
			<div className="static-modal" >
				<Modal.Dialog>
					<Modal.Body >

						<AutoSuggestContainer handleNameChange={this.__handleNameChange} />
						<br/>
						<br/>
						<Warning style={styles.info}>
							Only celeb pictures allowed.<br />
 							Non verified pics will be available in <br /> ur profile only or 
							direct link to the image.<br />
							You can visit your profile <br />on the right side @<br />
						</Warning>
						<br />
						<br />
						<ButtonToolbar style={styles.toolbar}>
							<Button
								bsStyle="primary"
								onClick={e =>
									onSubmit(isNSFW.length === 1, this.state.name)
								}
								style={styles.button1}
							>
								{"submit"}
							</Button>
							<Button
								bsStyle="danger"
								onClick={e =>
									onCancelConfirm()
								}
								style={styles.button}
							>
								{"cancel"}
							</Button>
						</ButtonToolbar>
						<img
							alt='' 
							style={styles.preview}
							src={previewSrc}
						/>
					</Modal.Body>
				</Modal.Dialog>
			</div>
		)
	}
}

class NewPostFormLiteContainer extends Component {
	state = {
		loading: false,
		isNSFW: [1],
		isOpen: [],
		showConfirm: false,
		previewSrc: null,
	};

	componentDidMount = () => this.props.onMount();

	onDrop = files => {
		this.file = files[0];

		this.setState({
			showConfirm: true
		})
	};

	onSubmit = (isNSFW, name) => {
		if (isNSFW) {
			isNSFW = 1
		} else {
			isNSFW = 0
		}

		if (name === '') {
			alert('Please enter celebrity name. Eg: britney spears')
			return;
		}

		if (name === 'tiktok') {
			alert('Please enter another name. This is only allowed for video url')
			return;
		}

		name = name.toLowerCase().replace(/ /g, '-');

		this.props.onSubmit(this.file, name, isNSFW);
		this.onCancelConfirm();
	}

	onCancelConfirm = () => {
		this.setState({
			showConfirm: false
		})
	}

	render() {
		const { isFetching, errorMessage, isAuthenticated, verified } = this.props;
		const { showConfirm } = this.state;
		if (!isAuthenticated) {
			return <Info style={styles.warning}>Login required: Please register/login to upload images/tiktok</Info>
		}

		if (!verified) {
			return (<Warning style={styles.warning}>
				Email unverified: Email is already send. Please verify your email id.
				After verifying logout and login again.
			</Warning>)
		}

		return (
			<div>
				{errorMessage !== "" ? <Alert>{errorMessage}</Alert> : null}

				<div>
					<FormGroup style={styles.container}>
						<Dropzone
							onDrop={this.onDrop}
							style={styles.dropzone}
							accept={"image/*"}
						>
							<div>+ Drop image here, or click to upload image</div>
						</Dropzone>
						{
							showConfirm &&
							<Confirm
								onCancelConfirm={this.onCancelConfirm}
								onSubmit={this.onSubmit}
								file={this.file}
							/>
						}
					</FormGroup>
					{isFetching ? <Loading /> : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { isAuthenticated, verified } = state.user

	const { isFetching, errorMessage } = state.loading;
	return {
		isFetching,
		errorMessage,
		isAuthenticated,
		verified
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSubmit: (file, name, isNSFW, isOpen) => {
			dispatch(addObject(file, name, isOpen, isNSFW));
		},
		onMount: () => {
			dispatch({ type: RESET });
		}
	};
};

const styles = {
	container: {
		padding: "4px",
		marginBottom: "4px",
		paddingBottom: 0
	},
	button: {
		marginLeft: '1rem'
	},
	preview: {
		width: "125px",
		height: "148px",
		float: "right",
		position: "absolute",
		right: "16px",
		top: "16px"
	},
	info: {
		//marginLeft: "1rem"
		widht: '100%'
	},
	dropzone: {
		width: "100%",
		borderWidth: "2px",
		borderColor: "rgb(102, 102, 102)",
		borderStyle: "dashed",
		borderRadius: "5px",
		textAlign: "center",
		opacity: "0.6",
		cursor: "pointer",
		marginTop: "40px",
		color: "white"
	},
	toolbar: {
		position: 'absolute',
		bottom: '1rem',
		right: '16px'
	},
	nsfwLabel: {
		position: 'absolute',
		bottom: '10px',
		left: '160px'
	},
	warning: {
		display: 'block',
		textAlign: 'center',
		marginLeft: '4px',
		marginRight: '4px',
		marginBottom: '4px',
		fontWeight: 400,
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(
	NewPostFormLiteContainer
);
