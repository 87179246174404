import React, { Component } from "react";
import { connect } from "react-redux";

import LogoIcon from "../icons/LogoIcon";
import Button from "../libs/Button";
import AdBlock from "../operations/AdBlock"
import AuthModalContainer from "../../containers/AuthModalContainer"
import ContactModalContainer from "../../containers/ContactModalContainer"
import NamesModalContainer from "../../containers/NamesModalContainer"
import AdBlockModalContainer from "../../containers/AdBlockModalContainer"
import InfiniteHypnoModalContainer from "../../containers/InfiniteHypnoModalContainer"
import NewTiktok from "../../containers/NewTiktokFormLiteContainer";

import CustomHeader from './CustomHeader';

import { toggleUNSAFE, logout } from "../../actions/actor";

class SectionHeader extends Component {
  state = {
    activeTab: "login",
    show: false,
    unsafe: false,
    showContact: false,
    showNames: false,
    showVerified: false,
    showHypno: false,
    showNewTiktok: false
  };

  render() {
    const { id, name, isAuthenticated, onLogout } = this.props;
    const { show, activeTab, showContact, showNames, showVerified, showHypno, showNewTiktok } = this.state;

    return (
      <CustomHeader id={id} name={name} isAuthenticated={isAuthenticated} />
    )

    return (
      <header style={styles.container}>
        <AdBlock>
          <AdBlockModalContainer />
        </AdBlock>
        <div style={styles.tags}>
          <Button style={styles.button} onClick={() => window.location.href = `/feedback`}>feedback</Button>
          &nbsp;&nbsp;
                    <Button style={styles.button} onClick={() => this.showNames()}> celebs</Button>
          &nbsp;&nbsp;
                    {
            name &&

            <Button style={styles.button} onClick={() => this.showHypno()}>
              preview
                    </Button>}
        </div>
        <div className="header-icon">
          <LogoIcon name={name} /> {/*- express using GIFs*/}
        </div>
        <div style={styles.auth}>
          {
            !isAuthenticated && <Button bsStyle={'primary'} onClick={() => this.handleLogin()} style={styles.login}>signup / login</Button>
          }
          {/*
            isAuthenticated && <label onClick={() => this.handleJournal(id)} style={styles.id}>{id}</label>
          */}
          {
            isAuthenticated && <label>
              <Button style={styles.button} onClick={() => window.location.href = `/@/${id}`}>{'@'}</Button>
              {/*&nbsp;&nbsp;
            <Button style={styles.button} onClick={() => this.handleJournal(id)}>{'GJ'}</Button>*/}
            </label>
          }
          &nbsp;&nbsp;
          {
            isAuthenticated && <Button onClick={() => onLogout()} style={styles.logout}>logout</Button>
          }
          &nbsp;&nbsp;
          {
            isAuthenticated && <Button onClick={() => this.showNewTiktok()} style={styles.logout}>upload tiktok</Button>
          }
        </div>
        {
          show && <AuthModalContainer show={show} activeTab={activeTab} handleCloseView={this.handleCloseView} />
        }
        {
          showContact && <ContactModalContainer show={show} handleCloseView={this.handleCloseContact} />
        }
        {
          showNames && <NamesModalContainer show={showNames} handleCloseView={this.handleCloseNameView} isVerified={false} />
        }
        {
          showVerified && <NamesModalContainer show={showNames} handleCloseView={this.handleCloseVerifiedView} isVerified={true} />
        }
        {
          showHypno && <InfiniteHypnoModalContainer show={showHypno} handleCloseView={this.handleCloseHypnoView} name={name} />
        }
        {
          showNewTiktok && <NewTiktok show={showNewTiktok} handleCloseView={this.handleCloseNewTiktok} name={name} />
        }
      </header>
    );
  }

  handleLogin = () => {
    this.setState({
      show: true,
      activeTab: "login"
    })
  }

  handleRegister = () => {
    this.setState({
      show: true,
      activeTab: "register"
    })
  }

  handleCloseView = () => {
    this.setState({
      show: false
    })
  }

  handleCloseNameView = () => {
    this.setState({
      showNames: false
    })
  }

  handleCloseVerifiedView = () => {
    this.setState({
      showVerified: false
    })
  }

  handleCloseHypnoView = () => {
    this.setState({
      showHypno: false
    })
  }

  toggleUnsafe = () => {
    this.setState({
      unsafe: !this.state.unsafe
    })
    this.props.toggleUnsafe()
  }

  showContact = () => {
    this.setState({
      showContact: true
    })
  }

  showHypno = () => {
    this.setState({
      showHypno: true
    })
  }

  showNewTiktok = () => {
    this.setState({
      showNewTiktok: true
    })
  }

  handleCloseContact = () => {
    this.setState({
      showContact: false
    })
  }

  handleCloseNewTiktok = () => {
    this.setState({
      showNewTiktok: false
    })
  }

  showNames = () => {
    window.open('/celebs', '_blank');
    /*
    this.setState({
      showNames: true
    })*/
  }

  showVerified = () => {
    this.setState({
      showVerified: true
    })
  }

  handleJournal = (id) => {
    window.location.href = `/${id}`;
  }

}

const mapStateToProps = (state, props) => {
  const { id, isAuthenticated } = state.user
  const { name } = props.match.params

  return {
    id,
    name,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLogout: () => {
      dispatch(logout())
    },
    toggleUnsafe: () => {
      dispatch(toggleUNSAFE())
    }
  };
};

const styles = {
  container: {
    textAlign: "center",
    position: "relative"
  },
  auth: {
    position: "absolute",
    top: '0px',
    right: '0px',
    margin: '10px'
  },
  tags: {
    position: "absolute",
    margin: '10px'
  },
  signup: {
  },
  id: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    fontStyle: 'italic',
    marginRight: '10px',
    cursor: 'pointer'
  },
  login: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    fontStyle: 'italic',
    border: '1px solid'
  },
  logout: {
    margin: "10x",
    background: '#282c34',
    color: 'white',
    border: '1px solid white',
  },
  unsetSafe: {
    background: "white",
    marginRight: "10px"
  },
  contact: {
  },
  button: {
    background: '#282c34',
    color: 'white',
    border: '1px solid white',
  },
  hbutton: {
    background: '#282c34',
    // color: 'white',
    // border: '1px solid white',
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader);