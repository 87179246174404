import { ACelebs } from "./ACelebs";
import { BCelebs } from "./BCelebs";
import { CCelebs } from "./CCelebs";
import { DCelebs } from "./DCelebs";
import { ECelebs } from "./ECelebs";
import { FCelebs } from "./FCelebs";
import { GCelebs } from "./GCelebs";
import { HCelebs } from "./HCelebs";
import { ICelebs } from "./ICelebs";
import { JCelebs } from "./JCelebs";
import { KCelebs } from "./KCelebs";
import { LCelebs } from "./LCelebs";
import { MCelebs } from "./MCelebs";
import { NCelebs } from "./NCelebs";
import { OCelebs } from "./OCelebs";
import { PCelebs } from "./PCelebs";
import { QCelebs } from "./QCelebs";
import { RCelebs } from "./RCelebs";
import { SCelebs } from "./SCelebs";
import { TCelebs } from "./TCelebs";
import { UCelebs } from "./UCelebs";
import { VCelebs } from "./VCelebs";
import { WCelebs } from "./WCelebs";
import { XCelebs } from "./XCelebs";
import { YCelebs } from "./YCelebs";
import { ZCelebs } from "./ZCelebs";

export const celebs = ACelebs.concat(
    BCelebs,
    CCelebs,
    DCelebs,
    ECelebs,
    FCelebs,
    GCelebs,
    HCelebs,
    ICelebs,
    JCelebs,
    KCelebs,
    LCelebs,
    MCelebs,
    NCelebs,
    OCelebs,
    PCelebs,
    QCelebs,
    RCelebs,
    SCelebs,
    TCelebs,
    UCelebs,
    VCelebs,
    WCelebs,
    XCelebs,
    YCelebs,
    ZCelebs
);
