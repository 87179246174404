import { REPLIES_PER_PAGE } from "../config/Config";

export const getReplies = (state, inReplyToId) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].inReplyTo === inReplyToId) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return replies;

	let sorted_replies = replies.sort(function(a, b) {
		if (a.order < b.order) return -1;
		if (a.order > b.order) return 1;
		return 0;
	});

	return sorted_replies;
};

export const getRepliesWithImages = (state, inReplyToId) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].inReplyTo === inReplyToId && state[id].image.href !== undefined) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_replies = replies.sort(function(a, b) {
		if (a.order < b.order) return -1;
		if (a.order > b.order) return 1;
		return 0;
	});

	return sorted_replies;
};


export const getRepliesWithImagesForName = (state, objectsById, name) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (objectsById[state[id].inReplyTo] && objectsById[state[id].inReplyTo].name === name && state[id].image.href !== undefined) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return replies;
};



export const getRepliesForAttributedTo = (state, attributedTo) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].attributedTo === attributedTo && state[id].image.href !== undefined) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_replies = replies.sort(function(a, b) {
		if (a.order > b.order) return -1;
		if (a.order < b.order) return 1;
		return 0;
	});

	return sorted_replies;
};

export const getRepliesWithContent = (state) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].content !== '' && state[id].image.href !== null && state[id].image.href !== undefined) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_replies = replies.sort(function(a, b) {
		if (a.order > b.order) return -1;
		if (a.order < b.order) return 1;
		return 0;
	});

	return sorted_replies;
};


export const getPaginatedReplies = (state, inReplyToId) => {

	if (state === undefined) {
		return [[], 0]
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].inReplyTo === inReplyToId) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	let sorted_replies = replies.sort(function(a, b) {
		if (a.order < b.order) return 1;
		if (a.order > b.order) return -1;
		return 0;
	});

	let filtered_replies = [];
	for (
		let page = 0;
		page < sorted_replies.length / REPLIES_PER_PAGE;
		page += 1
	) {
		filtered_replies.push(
			sorted_replies.slice(
				REPLIES_PER_PAGE * page,
				REPLIES_PER_PAGE * page + REPLIES_PER_PAGE
			)
		);
	}

	return [filtered_replies, Math.ceil(replies.length / REPLIES_PER_PAGE)];
};

export const getTotalReplies = (state, inReplyToId) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].inReplyTo === inReplyToId) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	return replies.length;
};


export const getRandomReply = (state, inReplyToId) => {
	if (state === undefined) {
		return []
	}

	let replies = Object.keys(state).reduce((obj, id) => {
		if (state[id].inReplyTo === inReplyToId) {
			obj.push(state[id]);
			return obj;
		}
		return obj;
	}, []);

	var rand = replies[Math.floor(Math.random() * replies.length)];

	return rand
};


export const getMinOrder = (state, attributedTo) => {
	if (state === undefined) {
		return []
	}

	let minOrder = 10000000000000;

	for (var id in state) {
		if (state[id].attributedTo === attributedTo) {
			minOrder = Math.min(minOrder, state[id].order);
		}
	}

	return minOrder
};


export const getMinOrderWithContent = (state) => {
	if (state === undefined) {
		return []
	}

	let minOrder = 10000000000000;

	for (var id in state) {
		if (state[id].content !== '') {
			minOrder = Math.min(minOrder, state[id].order);
		}
	}

	return minOrder
};