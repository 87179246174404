import {
    ADD_ONLINE_ACTORS_COUNT,
    SET_REACTIONS_LAST_ACCESSED_TIME
} from "../constants/ActionTypes";

export const fetchOnlineActorsSuccess = object => {
    return {
        type: ADD_ONLINE_ACTORS_COUNT,
        object
    };
}

export const flushAlerts = object => {
    return {
        type: ADD_ONLINE_ACTORS_COUNT,
        object
    };
}

export const reactionsLastAccessedTime = () => {
    return {
        type: SET_REACTIONS_LAST_ACCESSED_TIME,
    }
}