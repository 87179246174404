import { combineReducers } from 'redux';

import { pin } from './pin'
import { replies } from './replies'
import { feeds } from './feeds'
import { objects } from './objects'
import { reactions } from './reactions'
import loading  from './loading'
import user from './user'
import { realtime } from './realtime'


const rootReducer = combineReducers({
	pin,
	feeds,
	user,
	objects,
	reactions,
	replies,
	loading,
	realtime
});

export default rootReducer;
