import React, { Component } from "react";
import Icon from "react-icons/lib/fa/plus";

import history from "../../router/history";

export default class ExploreIcon extends Component {
    constructor(props) {
        super(props);
        this.style = {
            fontSize: "2em",
            color: "silver",
            marginRight: "25px",
            cursor: "pointer"
        };
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <Icon onClick={() => history.push("/submit")} style={this.style} />
        );
    }
}