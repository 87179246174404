import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_ACTOR,
  AUTH_ACTOR_FAILURE,
  AUTH_ACTOR_SUCCESS,
  ADD_ACTOR,
  ADD_ACTOR_SUCCESS
} from "../constants/ActionTypes";
import fetch from "isomorphic-fetch";
import history from "../router/history";
import { API_HOST } from "../config/Config";

const registerAPI = (email, password) => {
  return function (dispatch) {
    dispatch({ type: ADD_ACTOR });
    return fetch(`${API_HOST}/v1/actor`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    })
      .then(handleErrors)
      .then(response => {
        if (!response.ok) throw Error(response.status);
        return response.json()
      })
      .then(json => {
        const { id, identity, verified, gold } = json;
        dispatch({ type: AUTH_ACTOR_SUCCESS, id, identity, verified, gold })
        dispatch({ type: ADD_ACTOR_SUCCESS })
      })
      .catch((error) => {
        alert('email id already exist')
        dispatch({
          type: AUTH_ACTOR_FAILURE,
          errorMessage: "email id already exist"
        });
      });
  };
};

export const register = (email, password) => (dispatch, getState) => {
  return dispatch(registerAPI(email, password));
};

export function loginSuccess(json) {
  return {
    type: LOGIN_SUCCESS,
    username: json.username,
    access_token: json.access_token,
    uid: json.uid
  };
}

function handleErrors(response) {
  if (!response.ok) throw Error(response.status);
  return response;
}

const loginAPI = (email, password) => {
  return function (dispatch) {
    dispatch({ type: AUTH_ACTOR });
    return fetch(`${API_HOST}/v1/actor/authenticate`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    })
      .then(handleErrors)
      .then(response => {
        if (!response.ok) throw Error(response.status);
        return response.json()
      })
      .then(json => {
        const { id, identity, verified, active, gold } = json;

        if (!active) {
          alert('please try after some time. username and password do not match');
          return;
        }
        dispatch({ type: AUTH_ACTOR_SUCCESS, id, identity, verified, gold })
        history.push(`/`);
      })
      .catch((error) => {
        alert('username and password do not match')
        dispatch({
          type: AUTH_ACTOR_FAILURE,
          errorMessage: "username & password do not match"
        });
      });
  };
}

export const login = (email, password) => (dispatch, getState) => {
  return dispatch(loginAPI(email, password));
};

export const logout = () => (dispatch, getState) => {
  return dispatch({ type: LOGOUT_SUCCESS });
};