import { connect } from "react-redux";
import React, { Component } from "react";

import PostHolder from "../components/juxtapost/PostHolder";
import TrainPostHolder from "../components/train/PostHolder";
import LogoIcon from "../components/icons/LogoIcon";

import Info from "../components/libs/Info";
import Warning from "../components/libs/Warning";


import { fetchObject, incrViews, deleteObject } from "../actions/object.js";
import { forceClose } from "../actions/publish";
import { resetAlerts } from "../actions/publish";
import { fetchReplies } from "../actions/reply";


import Ad from "../components/operations/ChaturbateLargeAd";
import Modal1 from "../components/libs/Modal";

import LikeNotification from "../components/notifications/Like";
import ImageNotification from "../components/notifications/Image";
import GIFNotification from "../components/notifications/GIF";
import RequestNotification from "../components/notifications/Request";
import AdblockDetect from "../components/operations/AdblockDetect"

class PostContainer extends Component {
	state = {
		showReactionForm: false,
		event: 'story'
	};

	componentDidMount = () => {
		this.props.onMount();
	};

	componentWillUnmount = () => {
		this.props.onUnMount();
	};

	componentWillReceiveProps = nextProps => {
		if (this.props.alerts.length !== nextProps.alerts.length) {
			this.props.flushAlerts();
			nextProps.alerts.map(alert => {
				let node;
				if (alert.alertType === "like") {
					node = (
						<LikeNotification text={alert.text} href={alert.href} />
					);
				} else if (alert.alertType === "request") {
					node = (
						<RequestNotification
							text={alert.text}
							href={alert.href}
						/>
					);
				} else if (alert.alertType === "post") {
					node = (
						<ImageNotification
							text={alert.text}
							href={alert.href}
						/>
					);
				} else if (alert.alertType === "comment") {
					node = (
						<GIFNotification text={alert.text} href={alert.href} />
					);
				} else {
					node = `"${alert.text}"`;
				}

				return this.props.toastManager.add(node, {
					appearance: "success",
					autoDismiss: alert.autoDismiss
				});
			});
		}
	};

	componentWillReceiveProps_bak = nextProps => {
		if (this.props.alerts.length !== nextProps.alerts.length) {
			this.props.flushAlerts();
			nextProps.alerts.map(alert => {
				return this.props.toastManager.add(`"${alert.text}"`, {
					appearance: "success",
					autoDismiss: alert.autoDismiss
				});
			});
		}
	};

	_onChangeEvent = () => {
		let event = '';
		if (this.state.event === 'hypno') {
			event = 'story';
		} else {
			event = 'hypno';
		}
		this.setState({
			event
		})
	}

	render() {
		const { pid, post, isPreview, isAdmin, isAuthenticated } = this.props;
		const { event } = this.state;

		/*if (post.verified === false && isAuthenticated === false) {
			return (
				<div style={{maxWidth: '400px', margin: '0 auto'}}>
        <div className="header-icon" style={{textAlign: 'center', padding:'30px'}}>
          <LogoIcon name={'UGIFS'} /> 
					<Info style={styles.warning}>Please log in to view the post.</Info>
        </div>				
				<AdblockDetect>You are using adblock</AdblockDetect>
					<div style={{  }}>
						<Ad />
					</div>
			</div>	
			)
		}*/

		var a = new Date(); 
		var b = new Date(post.published);
		var seconds = parseInt((a-b)/1000);
		console.log(post);
		// if (isAdmin === false && post.verified === false && seconds > 3600*6 && post.name !== 'tiktok') {
		if (post.gold === false && isAdmin === false && post.verified === false && post.name !== 'tiktok') {
			//  alert('Non verified post older then 4 hours is only visible to the admin of the post');
			return (
				<div style={{maxWidth: '400px', margin: '0 auto'}}>
        <div className="header-icon" style={{textAlign: 'center', padding:'30px'}}>
          <LogoIcon name={'UGIFS'} /> 
					<Info style={styles.warning}>Non verified post is only visible to the admin of the post</Info>
        </div>				
				<AdblockDetect>You are using adblock</AdblockDetect>
					<div style={{  }}>
						<Ad />
					</div>
			</div>	
			)
		}

		if (isAdmin === false && post.verified === false && seconds > 3600*6 && post.name !== 'tiktok') {
			return (
				<div style={{maxWidth: '400px', margin: '0 auto'}}>
        <div className="header-icon" style={{textAlign: 'center', padding:'30px'}}>
          <LogoIcon name={'UGIFS'} /> 
					<Warning style={styles.warning}>Non verified post older then 4 hours is only visible to the admin of the post</Warning>
        </div>				
				<AdblockDetect>You are using adblock</AdblockDetect>
					<div style={{  }}>
						<Ad />
					</div>
			</div>	
			)
		}		

		if (isAdmin === false && post.verified === false) {
			// alert('Post is not verified and you are not the admin of the post')
			// return null;
		}

		return (
			<div>
				<AdblockDetect>You are using adblock</AdblockDetect>
				{
					/*isPreview === true && */event === 'hypno' &&
					<TrainPostHolder id={post.id} post={post} />
				}
				{
					event === 'story' &&
					<PostHolder id={post.id} post={post} />
				}
				{
					isPreview === false &&
					<div style={{ position: 'fixed', right: "10px", bottom: "10px", zIndex: 10000 }}>
						<Ad />
					</div>
				}
				<Modal1 pid={pid} show={this.state.showReactionForm} isNSFW={post.isNSFW} />
				{
					isPreview === false && this.renderHeader()
				}
				{
					isAdmin && post.verified === false && <div style={{color: 'silver', textAlign: 'center', opacity: '0.6'}}>{(3600*12 - seconds)/60} minutes left to be visible</div>
				}
			</div>
		);
	}

	renderHeader() {
		const { event } = this.state;
		const { post, onDelete, isAdmin, onFetchMore } = this.props;
		const { id, attributedTo } = post;
		return (
			<div style={styles.header}>
				{
					event === 'story' &&
					<div onClick={() => this._onChangeEvent()} style={{cursor: 'pointer', display: 'inline-block', padding: '10px', borderRight: '1px solid'}}>
						Hypnotize
				</div>
				}
				{
					event === 'hypno' &&
					<div onClick={() => this._onChangeEvent()} style={{cursor: 'pointer', display: 'inline-block', padding: '10px', borderRight: '1px solid'}}>
						Normal
				</div>
				}
				<div onClick={() => window.location.href='/' } style={{cursor: 'pointer', display: 'inline-block', padding: '10px'}}>
						Home
				</div>
				{ isAdmin && <div onClick={() => onDelete(id, attributedTo) } style={{cursor: 'pointer', display: 'inline-block', padding: '10px', borderLeft: '1px solid'}}>
						Delete
			</div> }
			{ isAdmin && <div onClick={() => onFetchMore(id, attributedTo) } style={{cursor: 'pointer', display: 'inline-block', padding: '10px', borderLeft: '1px solid'}}>
						Fetch More
			</div> }
			</div>
		)
	}

}

const mapStateToProps = (state, props) => {
	const { toastManager } = props;
	let { pid } = props;

	let isPreview = true;

	if (pid === undefined) {
		pid = props.match.params.pid;
		isPreview = false
	}


	let { alerts, actorId, isOpen } = state.realtime;
	const userId = state.user.id;

	const post = state.objects[pid] || {};
	const { attributedTo } = post;

	

	return {
		pid,
		post,
		alerts,
		toastManager,
		isOpen,
		isPreview,
		isAdmin: userId === actorId,
		isAuthenticated: state.user.isAuthenticated
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onMount: () => {
			let { pid } = props;
			if (pid === undefined) {
				pid = props.match.params.pid;

				dispatch(fetchObject(pid))
				dispatch(incrViews(pid))
			}
		},
		flushAlerts: () => {
			dispatch(resetAlerts());
		},
		onUnMount: () => {
			dispatch(forceClose());
		},
		onDelete: (id, attributedTo) => {
			if (window.confirm('Are you sure you want to delete ?')) {
				dispatch(deleteObject(id, attributedTo));
			}
		},
		onFetchMore: (id) => {
			dispatch(fetchReplies(id, 50));
		}
	};
};

const styles = {
	header: {
		position: 'absolute',
		top: 0,
		zIndex: 999999,
		background: '#282c34',
		color: 'white',
		margin: '10px',
		textAlign: 'center',
		pointer: 'cursor',
	},
	warning: {
		display: 'block',
		textAlign: 'center',
		marginLeft: '4px',
		marginRight: '4px',
		marginBottom: '4px',
		fontWeight: 300,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PostContainer);