import { connect } from "react-redux";
import React, { Component } from "react";

import Autosuggest from 'react-autosuggest';

import './css/theme.css';

import { celebs } from "../config/Celebs";

const languages = celebs;

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 ? [] : languages.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };
  
  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.name;
  
  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
    </div>
  );

class AutoSuggestContainer extends Component {

    state = {
        value: '',
        suggestions: []
    };

    onChange = (event, { newValue }) => {
        this.props.handleNameChange(newValue);
        this.setState({
            value: newValue
        });
    };
    
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    
    render() {
        const { value, suggestions } = this.state;
    
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'celebrity name Eg: britney spears',
            value,
            onChange: this.onChange
        };
    
        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
	return {

	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSuggestContainer);