import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  FormControl,
} from "react-bootstrap";
import history from "../router/history";

import Loading from "../components/libs/Loading";
import Button from "../components/libs/Button";
import Info from "../components/libs/Info";
import H1 from "../components/libs/H1";

import { register } from "../actions/login.js";


class RegisterFormContainer extends Component {
  componentDidMount = () => {
    if (this.props.isAuthenticated) {
      history.push(`/`);
    }
  };

  render() {
    const { onSubmit, isFetching, handleChangeView, handleCloseView } = this.props;

    if (isFetching) {
      return <Loading />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <H1>Register</H1>
          <Button onClick={() => handleCloseView()} style={styles.close}>
            <img
              alt=''
              style={styles.closeIcon}
              src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGrBEOYRD8RwEdtCO_zo2SYtpUyReEb6IjWDwAZqb5iH8zMb8k4w"}
            />
          </Button>
        </div>
        <div>
          <FormGroup>
            <FormControl
              inputRef={ref => {
                this.email = ref;
              }}
              //style={{padding: '5px', marginBottom: '10px'}}
              style={styles.input}
              type={"text"}
              placeholder={"email"}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              inputRef={ref => {
                this.password = ref;
              }}
              //style={{padding: '5px', marginBottom: '10px'}}
              style={styles.input}
              type={"password"}
              placeholder={"password"}
            />
          </FormGroup>
          <FormGroup>
            <Button
              bsStyle="primary"
              onClick={e =>
                onSubmit(this.email.value, this.password.value)
              }
              style={styles.button}
            >
              {"Sign up"}
            </Button>
          </FormGroup>
        </div>
        <FormGroup>
          <Info>
            {"Have an account? "}
            <Button onClick={() => handleChangeView('login')}>
              {'Login'}
            </Button>
          </Info>
        </FormGroup>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: false,
    handleChangeView: props.handleChangeView,
    handleCloseView: props.handleCloseView
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (email, password) => {
      props.handleCloseView();
      dispatch(register(email, password));
    }
  };
};

const styles = {
  container: {
    textAlign: "left"
  },
  center: {
    margin: "auto",
    marginTop: "50px",
    textAlign: "center"
  },
  header: {
    position: "relative",
    marginTop: "20px",
    marginBottom: "20px"
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0
  },
  closeIcon: {
    width: "29px"
  },
  input1: {
    width: "50%",
    height: "3rem",
    marginRight: "5px",
    borderRadis: "5px",
    outline: "none",
    fontSize: "16px",
    padding: "5px",
    marginBottom: "10px",
    mapStateToPropsborder: 0,
    border: 0,
    borderBottom: "1px solid #ddd",
    background: "rgb(246,249,252)"
  },
  input: {
    height: "3rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
  button: {},
  button1: {
    height: "3rem",
    background: "#3A2FD6",
    color: "white",
    fontSize: "20px",
    fontWeight: 300,
    borderRadis: "5px",
    marginTop: "15px"
  },
  logo: {
    width: "50px"
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterFormContainer
);