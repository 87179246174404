import {
	FETCH_OBJECT_SUCCESS,
	DELETE_OBJECT_SUCCESS,
	DELETE_REACTION_SUCCESS
} from "../constants/ActionTypes";

import { object } from "./object";

export const objects = (state = {}, action) => {
	switch (action.type) {
		/*case FETCH_OBJECTS_SUCCESS:
			return {
				...state,
				...action.objects.reduce((obj, val) => {
					let actionVal = { post: val, type: action.type };
					obj[val.id] = object(state[val.id] || {}, actionVal);
					return obj;
				}, {})
			};*/
		case FETCH_OBJECT_SUCCESS:
			return {
				...state,
				[action.object.id]: object(state[action.object.id], action)
			};

		case DELETE_OBJECT_SUCCESS:
			return {
				...Object.keys(state)
					.filter(id => id !== action.id)
					.reduce((obj, currId) => {
						obj[currId] = state[currId];
						return obj;
					}, {})
			};
		case DELETE_REACTION_SUCCESS:
			return {
				...Object.keys(state)
					.reduce((obj, currId) => {

						if (state[currId].type === 'POST') {
							obj[currId] = state[currId];
						}
						
						return obj;
					}, {})
			};
		default:
			return state;
	}
};