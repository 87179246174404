import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, InputGroup, FormControl } from "react-bootstrap";
import { RESET } from "../../constants/ActionTypes";

import { addName } from "../../actions/moderate.js";

import Button from "../libs/Button";
import Alert from "../libs/Alert";
import Loading from "../libs/Loading";

import { fetchNames } from "../../actions/names";
import { getAllNames } from "../../utils/names";

class ModerateName extends Component {
	state = {
		loading: false,
		name: "",
		href: ""
	};

	componentDidMount = () => this.props.onMount();

	onSubmit = () => {
		this.props.onSubmit(this.state.name, this.state.href);
	};

	__handleNameChange = e => {
		this.setState({ name: e.target.value });
	};

	__handleHrefChange = e => {
		this.setState({ href: e.target.value });
	};

	render() {
		const { names, isFetching, errorMessage } = this.props;
		return (
			<div>
				{errorMessage !== "" ? <Alert>{errorMessage}</Alert> : null}

				<div style={{ maxWidth: "467px", marginTop: "10px" }}>
					<FormGroup>
						<InputGroup>
							<FormControl
								type="text"
								placeholder="name"
								value={this.state.name}
								onChange={this.__handleNameChange}
							/>
							<br />
							<FormControl
								type="text"
								name="href"
								value={this.state.href}
								onChange={this.__handleHrefChange}
							/>
							<br />
							<Button
								bsStyle={"primary"}
								onClick={() => this.onSubmit()}
							>
								{"submit"}
							</Button>
						</InputGroup>
					</FormGroup>
					{isFetching ? <Loading /> : null}
				</div>

				{
					names.map((name) => {
						return <div style={{ color: 'white' }} key={name.id}>{name.name}</div>
					})
				}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { isFetching, errorMessage } = state.loading;
	const names = getAllNames(state.objects);
	return {
		names,
		isFetching,
		errorMessage
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSubmit: (name, href) => {
			dispatch(addName(name, href));
		},
		onMount: () => {
			dispatch({ type: RESET });
			dispatch(fetchNames());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerateName);