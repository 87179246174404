import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

class AdBlockModalContainer extends Component {

  render() {
    return (
      <div className="static-modal" style={styles.wrapper}>
        <Modal.Dialog>
          <Modal.Body style={styles.container}>
            <label style={{ color: 'green' }}>Why this pop-over ?</label>
            <label style={{ color: 'red' }}>
              PLEASE DISABLE THE AdBlock plugin on your browser only
              for the website ugifs.com and refresh the page.
                        </label>
            <br />
            <br />

            <label style={{ color: 'green' }}>Ads are bad ?</label>
            <br />
            We only show one Ad and its not intrusive.
                        <br />
            We do not show pop overs ad.
                        <br />
            We try to keep Ad as simple and non intruisive as possible.
                        <br />
            Ads are only from Chaturbate website.
            (its a well renowned cam website)
                        <br /><br />

            <label style={{ color: 'green' }}>
              Why should i disable it ?</label>
            <br />
            Running a website needs funds/money
            and Ad helps in a small way.
                        <br />This is your way to help maintain the website.
                    </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
};

var styles = {
  wrapper: {
    width: "400px",
    textAlign: "left",
    fontSize: "1.3em"
  },
  container: {
    maxHeight: 'calc(80vh)',
    overflowY: 'auto',
    padding: '5px',
    fontSize: '0.9em'
  },
  closeIcon: {
    width: "29px"
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0
  },
  input: {
    height: "5rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AdBlockModalContainer);