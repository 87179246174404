import {
	FETCH_REPLIES_SUCCESS,
	ADD_REPLY_SUCCESS,
} from "../constants/ActionTypes";

export const reply = (state, action) => {
	switch (action.type) {
		case ADD_REPLY_SUCCESS:
			return {
				...state,
				...action.reply
			};
		case FETCH_REPLIES_SUCCESS:
			return {
				...state,
				...action.reply
			};
		default:
			return state;
	}
};