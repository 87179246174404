import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import LoginFormContainer from "./LoginFormContainer"
import RegisterFormContainer from "./RegisterFormContainer"


class AuthModalContainer extends Component {
  state = {
    activeTab: "login",
  };

  componentDidMount = () => {
    this.setState({
      activeTab: this.props.activeTab
    })
  }

  componentWillReceiveProps = nextProps => {
  };

  render() {
    const { activeTab } = this.state;
    const { handleCloseView } = this.props;

    return (
      <div className="static-modal" style={styles.container}>
        <Modal.Dialog>
          <Modal.Body >
            {
              activeTab === 'login' &&
              <LoginFormContainer
                handleChangeView={this.handleChangeView}
                handleCloseView={handleCloseView}
              />
            }
            {
              activeTab === 'register' &&
              <RegisterFormContainer
                handleChangeView={this.handleChangeView}
                handleCloseView={handleCloseView}
              />
            }
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }

  handleChangeView = (activeTab) => {
    this.setState({
      activeTab
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    show: props.show,
    activeTab: props.activeTab,
    handleCloseView: props.handleCloseView
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: () => {
    },
    onMount: () => {
    }
  };
};

var styles = {
  container: {
    width: "400px"
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModalContainer);