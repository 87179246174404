import {
  AUTH_ACTOR_SUCCESS,
  LOGOUT_SUCCESS,
  TOGGLE_UNSAFE
} from "../constants/ActionTypes";

import { loadState, saveState, removeState } from "../utils/authentication"

export default function user(state = loadState(), action) {
  switch (action.type) {
    case AUTH_ACTOR_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        ...saveState(action.id, action.identity, true, action.verified, action.gold)
      }
    case LOGOUT_SUCCESS:
      return {
        isAuthenticated: false,
        isNSFW: 1,
        ...removeState()
      }
    case TOGGLE_UNSAFE:
      return {
        ...state,
        isNSFW: 1 - state.isNSFW
      }
    default:
      return state;
  }
}