import {
	SHOW_PIN,
} from "../constants/ActionTypes";

const __showPin = (top, left, inReplyTo) => {
	return {
		type: SHOW_PIN,
		show: true,
		top,
		left,
		inReplyTo
	};
}

const __hidePin = () => {
	return {
		type: SHOW_PIN,
		show: false,
		top: 0,
		left: 0,
		inReplyTo: null,
	};
}

export const showPin = (top, left, inReplyTo) => (dispatch, getState) => {
   dispatch(__showPin(top, left, inReplyTo))
 }

 export const hidePin = () => (dispatch, getState) => {
   dispatch(__hidePin())
 }