import {
  FETCH_OBJECT,
  FETCH_OBJECT_SUCCESS,
  FAILURE,
  DELETE_OBJECT_SUCCESS
} from "../constants/ActionTypes";

import history from "../router/history";

import { API_HOST } from "../config/Config";

import { sendMessage } from "./socket"
import { deleteObject } from "./object";

export const fetchObjectSuccess = object => {
  return {
    type: FETCH_OBJECT_SUCCESS,
    object
  };
}


const __fetchObjectsAPI = (actorId) => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/objects?type=POST&active=true&verified=false`, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        json.items.map((item) => {
          return dispatch(fetchObjectSuccess(item.object))
        })
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchObjects = () => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__fetchObjectsAPI(actorId));
};


const __banImageAPI = (id, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/api/ban/image/${id}/${actorId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(json => {
        //dispatch({type: DELETE_OBJECT_SUCCESS, id})
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const banImage = id => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__banImageAPI(id, actorId));
};

const __banIPAPI = (id, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/api/ban/ip/${id}/${actorId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(sendMessage({ type: 'delete', id }));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        alert('Request failed')
        console.log(error)
      })
  };
}

export const banIP = id => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__banIPAPI(id, actorId));
};


const __banActorAPI = (id, attributedTo, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/actor/${attributedTo}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active: false,
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch(sendMessage({ type: 'delete', id }));
        dispatch(sendMessage({ type: "logout", attributedTo: attributedTo }));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        alert('Request failed')
        console.log(error)
      })
  };
}

export const banActor = (id, attributedTo) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__banActorAPI(id, attributedTo, actorId));
};



const __addWebmAPI = (href, isNSFW, actorId) => {
  return function (dispatch) {
    // return fetch(`${API_HOST}/v1/api/reaction`, {
    return fetch(`http://127.0.0.1:3031/v1/reaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        href,
        actorId
      })
    })
      .then(response => response.json())
      .then(json => {
        //console.log('Added webm')
        history.push('/webm')
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        alert('Request failed')
        console.log(error)
      })
  };
}

export const addWebm = (href, isNSFW) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__addWebmAPI(href, isNSFW, actorId));
};


const __addGifAPI = (href, race, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/reaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        href,
        race,
        actorId
      })
    })
      .then(response => response.json())
      .then(json => {
        //console.log('Added webm')
        history.push(`/submit/${race}`)
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        alert('Request failed')
        console.log(error)
      })
  };
}

export const addGif = (href, race) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__addGifAPI(href, race, actorId));
};



const __addTagAPI = (tag, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/api/tag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tag,
        actorId
      })
    })
      .then(response => response.json())
      .then(json => {
        history.push('/tag')
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        alert('Request failed')
        console.log(error)
      })
  };
}

export const addTag = (tag) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__addTagAPI(tag, actorId));
};


const __fetchHReactionsAPI = (actorId) => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/api/hreactions/unverified`, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        return json.items.map((item) => {
          return dispatch(fetchObjectSuccess(item))
        })
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchHReactions = () => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__fetchHReactionsAPI(actorId));
};



const __fetchReactionsAPI = (actorId) => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/reactions?type=Reaction&verified=0&allowed=1`, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        /*return json.items.map((item) => {
            dispatch(fetchObjectSuccess(item))
        })
        */
       return json.map((item) => {
        return dispatch(fetchObjectSuccess(item))
      })
      /*
        return json.items.map((item) => {
          return dispatch(fetchObjectSuccess(item))
        })*/
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const fetchReactions = () => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__fetchReactionsAPI(actorId));
};


const __updateReactionStatusAPI = (id, pass, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/reaction/${id}/verify`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        allowed: pass === 1,
        processing: true,
        actorId,
      })
    })
      .then(json => {
        id = id + ""
        dispatch({ type: DELETE_OBJECT_SUCCESS, id });
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const passReaction = id => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__updateReactionStatusAPI(id, 1, actorId));
};

export const failReaction = id => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__updateReactionStatusAPI(id, 0, actorId));
};




const __addContactTextAPI = (text) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        text,
      })
    })
      .then(response => response.json())
      .then(json => {
        alert('success fully send')
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const addContactText = (text) => (dispatch, getState) => {
  return dispatch(__addContactTextAPI(text))
};


const __verifiedPostAPI = (id, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/object/${id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        verified: true,
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: DELETE_OBJECT_SUCCESS, id });
        //dispatch(sendMessage({type:'delete', id}));
        //dispatch(sendMessage({ type: "logout", attributedTo: attributedTo }));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const verifiedPost = (id) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__verifiedPostAPI(id, actorId));
};



const __approvedPostAPI = (id, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/object/${id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        approved: true,
      })
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: DELETE_OBJECT_SUCCESS, id });
        //dispatch(sendMessage({type:'delete', id}));
        //dispatch(sendMessage({ type: "logout", attributedTo: attributedTo }));
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const approvedPost = (id) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__approvedPostAPI(id, actorId));
};


const __addFeedbackAPI = (text) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/actor/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        text,
      })
    })
      .then(response => response.json())
      .then(json => {
        alert('success fully send')
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const addFeedback = (text) => (dispatch, getState) => {
  return dispatch(__addFeedbackAPI(text))
};


const __addNameAPI = (name, href, actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/name`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        href,
        actorId
      })
    })
      // .then(response => response.json())
      .then(json => {
        history.push('/name')
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const addName = (name, href) => (dispatch, getState) => {
  const actorId = getState().user.id
  return dispatch(__addNameAPI(name, href, actorId));
};


export const __deleteUnverifiedPosts = (actorId) => {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECT });
    return fetch(`${API_HOST}/v1/api/admin/delete/unverified/posts/${actorId}`, {
      method: "GET",
      headers: {
        "Content-Type": "apipplication/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        history.push(`/moderate`)
      })
      .catch(error => {
        dispatch({ type: FAILURE, errorMessage: 'something went wrong on our side.' })
        console.log(error)
      })
  };
}

export const deleteUnverifiedPosts = (objects) => (dispatch, getState) => {
  // const actorId = getState().user.id
  objects.map(object => {
    return dispatch(deleteObject(object.id, undefined));
  })
};
