import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { getUnsafeReactions } from "../../utils/reactions";
import { fetchReactions } from "../../actions/reactions";
import { addReply } from "../../actions/reply.js";
import { hidePin } from "../../actions/pin";
import './modal1.css';


import ReactionComments from "../swipe-comment/ReactionComments";

class Modal1 extends Component {
  state = {
    activeTab: "unsafe"
  };

  constructor(props) {
    super(props);
    this.style = {};
    if (props.style) {
      this.style = { ...this.style, ...props.style };
    }
  }

  componentDidMount = () => { };

  componentWillReceiveProps = nextProps => {
    if (nextProps.show === true && this.props.show === false) {
      this.props.onMount();
    }
  };


  render() {
    const { reactions, show, allowed } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className="preview static-modal">
        <Modal.Dialog style={styles.container}>
          <Modal.Body style={styles.body}>
            <Button
              onClick={() => this.props.onClose()}
              style={styles.button}
              bsStyle={"warning"}
              
            >
              &#10005;
                        </Button>
            <ReactionComments
              reactions={reactions}
              onSubmit={this.onSubmit}
            />
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }



  onSubmit = (e, href) => {
    e.stopPropagation();
    const { top, left, inReplyTo, pid } = this.props;
    this.props.onSubmit(e, href, inReplyTo, top, left, pid, 1);
    this.props.onClose();
  };
}

const mapStateToProps = (state, props) => {
  const reactions = getUnsafeReactions(state.reactions);
  const { top, left, inReplyTo, show } = state.pin;

  return {
    show,
    top,
    left,
    inReplyTo,
    reactions,
    allowed: props.allowed
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(fetchReactions());
    },
    onSubmit: (e, href, inReplyTo, top, left, pid, isNSFW) => {
      dispatch(addReply("", href, inReplyTo, top, left, pid, isNSFW));
    },
    onClose: () => {
      dispatch(hidePin());
    },
  };
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '1.75rem auto',

  },
  wrapper: {

  },
  body: {
    padding: '1em'
  },
  button: {
    width: '100%',
    marginBottom: '10px',
    borderColor: 'white',
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal1);