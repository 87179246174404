export const getAllNames = (state) => {
	if (state === undefined) {
		return []
	}

	let objects = Object.keys(state).reduce((obj, id) => {
			if (state[id].type === 'NAME') {
				obj.push(state[id]);
				return obj;
			}
			return obj;
		}, []);

	let sorted_objects = objects.sort(function(a, b) {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

	return sorted_objects;
};


export const getAllVerifiedNames = (state) => {
	if (state === undefined) {
		return []
	}

	let objects = Object.keys(state).reduce((obj, id) => {
			if (state[id].type === 'NAME' && state[id].name.indexOf('verified') >= 0) {
				obj.push(state[id]);
				return obj;
			}
			return obj;
		}, []);

	let sorted_objects = objects.sort(function(a, b) {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

	return sorted_objects;
};