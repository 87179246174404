import React, { Component } from "react";
import { connect } from "react-redux";

import LogoutIcon from "../icons/LogoutIcon";
import SubmitIcon from "../icons/SubmitIcon";
import DeleteIcon from "../icons/DeleteIcon";

import { logout } from "../../actions/login";
import { deleteObject } from "../../actions/object";

class PostHeader extends Component {
  __renderAuthenticatedIcons = () => {
    return (
      <div className="header-info" style={{ float: "left" }}>
        <SubmitIcon />
        <LogoutIcon />
      </div>
    );
  };

  __renderUnAutheticatedIcons = () => {
    return (
      <div className="header-info" style={{ display: "inlineBlock" }}>
        <DeleteIcon onDelete={this.props.onDelete} />
      </div>
    );
  };

  __onDelete = () => {
    this.props.onDelete(this.props.id, this.props.actorId);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  const { id, expiry, actorId } = state.realtime;
  const userId = state.user.id;

  return {
    id,
    actorId,
    expiry,
    isAdmin: userId === actorId
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLogout: () => {
      dispatch(logout());
    },
    onDelete: (id, actorId) => {
      dispatch(deleteObject(id, actorId));
    },
    onRequestForMore: () => {
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostHeader);