import React, { Component } from "react";

import Button from "../libs/Button";

export default class DeleteIcon extends Component {
    constructor(props) {
        super(props);
        this.style = {};
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <Button 
                style={styles.button} 
                onClick={() => this.props.onClick()}
                bsStyle={"danger"}
            >
                delete
            </Button>
        );
    }
}


const styles = {
    button: {
        borderRadius: "4px",
        backgroundColor: "#FFEBE6",
        color: "#BF2600",
        boxShadow: '0 3px 8px rgba(0,0,0,0.175)',
    }
};