import {
    FETCH_REACTIONS,
    FETCH_REACTIONS_SUCCESS,
} from "../constants/ActionTypes";
import { API_HOST } from "../config/Config";

import { reactionsLastAccessedTime } from "./realtime"


export const fetchReactionsSuccess = reactions => {
    return {
        type: FETCH_REACTIONS_SUCCESS,
        reactions
    };
};

const __fetchReactionsAPI = () => {
    return function(dispatch) {
        dispatch({type: FETCH_REACTIONS});
        // return fetch(`${API_HOST}/v1/api/reactions`, {
        return fetch(`${API_HOST}/v1/reactions?type=Reaction&verified=true&allowed=true`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(response => response.json())
        .then(json => {
            dispatch(reactionsLastAccessedTime())
            dispatch(fetchReactionsSuccess(json))
        })      
        .catch(e => {
            console.log("Error: ", e);
        });
    }
}

/*const __shouldFetchReactions = startTime => {
    let endTime = new Date();
    var timeDiff = endTime - startTime; //in ms
    // strip the ms
    timeDiff /= 1000;

    // get seconds
    var seconds = Math.round(timeDiff);
    return seconds > 10 * 1; // 1 mins
};*/

export const fetchReactions = () => (dispatch, getState) => {    
    //const { reactionsLastAccessedTime } = getState().realtime;
    return dispatch(__fetchReactionsAPI());
    /*if (reactionsLastAccessedTime === null || __shouldFetchReactions(reactionsLastAccessedTime)) {
        return dispatch(__fetchReactionsAPI());
    }*/
};
