import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import Button from "../libs/Button"
import { Link } from "react-router-dom";


import { getAllUnverfiedObjects } from "../../utils/moderate";
import { fetchObjects, banActor, banImage, verifiedPost, deleteUnverifiedPosts } from "../../actions/moderate";
import { deleteObject } from "../../actions/object";

const ModerateItem = ({
  id,
  name,
  isBt,
  views,
  published,
  attributedTo,
  image_thumb,
  onPostClick,
  onDelete,
  onBanActor,
  onVerifiedPost,
  customStyle
}) => (
    <tr style={customStyle}>
      <td>
      <label style={{customStyle}}>{published.split('T')[0]}</label>
      </td>
      <td style={{padding: 0, width: '100px'}}>
        {
          name === 'tiktok' && 
          <img src='https://www.refinery29.com/images/9579722.jpg' 
          onClick={() => onPostClick(id)}
          style={styles.image}
          />
        }
        {
          name !== 'tiktok' && 
        <img
        alt={""}
        style={styles.image}
        src={image_thumb && image_thumb.href}
        onClick={() => onPostClick(id)}
      />
        }

      </td>
      <td>
        <label style={{...styles.name, ...customStyle}}>{name}</label>
        <br></br>
        <label style={{...styles.views, ...customStyle}}>views: {views} &nbsp;
        </label>
      </td>
      <td>
        <Button bsStyle="success" onClick={() => onVerifiedPost(id)}>
          verified
            </Button>
      </td>
      <td>
        <Button bsStyle="success" onClick={() => onVerifiedPost(id)}>
          approved
            </Button>
      </td>
      <td>
        <Button bsStyle="danger" onClick={() => onDelete(id, attributedTo)}>
          delete
            </Button>
      </td>
      <td>
        <Button bsStyle="warning" onClick={() => onBanActor(id, attributedTo)}>
          ban actor
            </Button>
      </td>
      <td>
      {/*<a  target="_blank" style={styles.a} onClick={ () => window.location.href = `/${attributedTo}`}>GJ</a>
      &nbsp;&nbsp;&nbsp;&nbsp;*/}
      <a target="_blank" style={styles.a} onClick={ () => window.open(`/@/${attributedTo}`, '_blank')}>{attributedTo}</a>
      <br></br>
      </td>
    </tr>
  );

class Moderate extends Component {
  componentDidMount = () => {
    this.props.onMount();
  };

  componentWillUnmount = () => {
    this.props.onUnMount();
  };

  __render_post = id => {
    const url = `/i/${id}`
    var win = window.open(url, '_blank');
    win.focus();
  };

  render() {
    const { objects, onDelete, onBanActor, onBanImage, onVerifiedPost, onDeleteUnverifiedPosts } = this.props;
    return (
      <div>

        <Table striped bordered condensed hover>
          <tbody>
            {objects.map((object) => (
              <ModerateItem
                key={object.id}
                name={object.name}
                isBt={object.isBt}
                id={object.id}
                views={object.views}
                attributedTo={object.attributedTo}
                published={object.published}
                image={object.image}
                image_thumb={object.image}
                inReplyTo={object.inReplyTo}
                onPostClick={this.__render_post}
                onDelete={onDelete}
                onBanActor={onBanActor}
                onBanImage={onBanImage}
                onVerifiedPost={onVerifiedPost}
                customStyle={object.isBt ? {'background': 'white', color: 'black', fontFamily: 'monospace'}: {'background': '#282c34', color: 'white', fontFamily: 'monospace'}}
              />
            ))}
          </tbody>
        </Table>
        <p style={{fontFamily: 'monospace'/*position: 'fixed', left: '20px'*/}}>
          <br />
          <Button
            bsStyle={'danger'}
            onClick={() => onDeleteUnverifiedPosts(objects)}
          >
            DELETE UNVERIFIED
                    </Button>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const objects = getAllUnverfiedObjects(state.objects);

  return {
    objects
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      // dispatch(onInit('home'))
      dispatch(fetchObjects());
    },
    onDelete: (id, attributedTo, actorId) => {
      dispatch(deleteObject(id, attributedTo, actorId));
    },
    onBanImage: (id, attributedTo) => {
      dispatch(banImage(id));
    },
    onBanActor: (id, attributedTo) => {
      dispatch(banActor(id, attributedTo));
    },
    onVerifiedPost: (id, attributedTo) => {
      dispatch(verifiedPost(id, attributedTo));
    },
    onApprovedPost: (id, attributedTo) => {
      dispatch(verifiedPost(id, attributedTo));
    },
    onUnMount: () => {
      // dispatch(forceClose());
    },
    onDeleteUnverifiedPosts: (objects) => {
      dispatch(deleteUnverifiedPosts(objects))
    }
  };
};

const styles = {
  container: {},
  image: {
    width: "100px",
    cursor: "pointer",
    color: "white",
    fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace!important',
  },
  a: {
    cursor: 'pointer',
    color: 'white',
    fontSize: '20px',
    fontFamily: 'monospace'
  },
  name: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'monospace'
  },
  views: {
    textAlign: 'center',
    color: 'yellow',
    fontSize: '12px',
    fontFamily: 'monospace'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Moderate);