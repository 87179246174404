import React from 'react';
import { Route } from 'react-router';

import PostHeader from '../components/header/PostHeader';
import SectionHeader from '../components/header/SectionHeader'
import CenterHeader from '../components/header/CenterHeader'
import CustomHeader from '../components/header/CustomHeader';

import { BODY_WIDTH } from '../config/Config';

export const JuxtaPostLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={matchProps => (
        <div className="Post">  
          <Component {...matchProps} />
          <PostHeader />
        </div>
      )}
    />
  );
};

export const SectionLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={matchProps => (
        <div>
          <SectionHeader {...matchProps} />
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

export const CenterLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={matchProps => (
        <div style={styles.jcenter}>
          <CenterHeader {...matchProps} />
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

export const AdminLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={matchProps => (
        <div className="Post" style={{...styles.admin}}>
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

export const NoMatchLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className="DefaultLayout">
          <CustomHeader />
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};

const styles = {
  center: {
    margin: 'auto',
    width: BODY_WIDTH,
    maxWidth: '100%',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace!important',
    //backgroundColor: '#021c1e',
    backgroundColor: '#282c34',
    fontSize: '1.15rem',
    lineHeight: '1.5',
    fontWeight: 300,
    fontStyle: 'normal',
    WebkitFontSmoothing: 'antialiased'
  },
  jcenter: {
    margin: 'auto',
    width: '700px',
    maxWidth: '100%',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace!important',
    //backgroundColor: '#021c1e',
    backgroundColor: '#282c34',
    fontSize: '1.15rem',
    lineHeight: '1.5',
    fontWeight: 300,
    fontStyle: 'normal',
    WebkitFontSmoothing: 'antialiased'
  },
    logincenter: {
        width: '400px',
        marginTop: '80px',
        background: 'white',
        borderRadius: '10px',
        padding: '20px',
        border: '1px solid silver'
    },
    submitcenter: {
        width: '400px',
    },
    admin: {
      margin: 'auto',
      width: '900px',
      maxWidth: '100%',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      //backgroundColor: '#021c1e',
      backgroundColor: '#282c34',
      fontSize: '1.15rem',
      lineHeight: '1.5',
      fontWeight: 300,
      fontStyle: 'normal',
      WebkitFontSmoothing: 'antialiased'
    },
};
