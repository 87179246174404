import React, { Component } from "react";
import { connect } from "react-redux";
import Img from 'react-image'
import VisibilitySensor from 'react-visibility-sensor'

import './Section.css'
import './font.css'
// import './logo-icon.css';



import { LazyLoadImage } from 'react-lazy-load-image-component';

const MyImage = ({ image, content, item, style }) => (
  <VisibilitySensor>
  <div style={styles.wrapper} >
    
    <Img
      src={image && image.href} // use normal <img> attributes as props
      width={'100%'}
      loader={
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/50/Black_colour.jpg" />
      }
      style={styles.image}
    />
    <div className={item} style={{...styles.gifText, ...style, ...styles.gifTextDefault}}>
      {content}
    </div>
    
  </div>
  </VisibilitySensor>
);

class Item extends Component {

  render() {

    const { image, content } = this.props;
    const classNames = ['rock', 'rock-yellow', 'rock-pink'];
    var item = classNames[Math.floor(Math.random() * classNames.length)];
    let style = {};
    if (content.length > 10) {
      style = styles.gifSmaller;
    }

    if (content.length < 10) {
      item = 'retro-yellow';
    }

    item = 'default'

    return (
      <div>
        <MyImage image={image} content={content} item={item} style={style} />
        <div style={styles.desc}></div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id, content, image } = props

  return {
    id,
    content,
    image,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
};

const styles = {
  wrapper: {
    position: "relative",
    marginTop: '60px'
  },
  image: {
    width: "100%",
    cursor: "pointer",
    minHeight: '200px',
    /*border: '1px solid white'*/
    /*border: '1px solid rgba(255, 255, 255, 0.9)'*/
  },
  gifText: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 99999999,
    bottom: '10px',
    left: '1%',
    right: '1%',
  },
  gifTextDefault2: {
    fontSize: '30px',
    fontWeight: 'bolder',
    color: 'white',
    WebkitTextFillColor: 'yellow', /* Will override color (regardless of order) */
    WebkitTextStrokeWidth: '1px',
    WebkitTextStrokeColor: 'black',
  },
  gifTextDefault: {
    fontFamily: 'Russo none',
    textShadow: '5px 0 3px rgba(251, 0, 231, 0.8), 0 5px 3px rgba(0, 233, 235, 0.8), -5px 0 3px rgba(0, 242, 14, 0.8), 0 -5px 3px rgba(244, 45, 0, 0.8), 5px 0 3px rgba(59, 0, 226, 0.8)',
    color: 'rgba(255, 255, 255, 0.9)',
    /* transform: translateY(-100%); */
    color: 'rgba(255, 255, 255, 0.9)',
    /*background: 'rgba(0, 0, 0, 0.8)',*/
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    /* height: 0.5em; */
    fontSize: '3em'
  },
  gifSmaller: {
    font: '50px'
  },
  desc: {
    background: 'gray',
    border: '1px solid white',
    opacity: '0.2',
    marginTop: '60px'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);