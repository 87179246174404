import React, { Component } from "react";
import Button from "../libs/Button";

class Pagination extends Component {
    render() {
        const {
            currentPage,
            totalPages,
            onPreviousClick,
            onNextClick
        } = this.props;

        return (
            <div style={styles.center}>
                <Button onClick={() => onPreviousClick()} style={styles.button}>
                    {"<<"}
                </Button>

                <Button onClick={() => onNextClick()} style={styles.button}>
                    {">>"}
                </Button>
                <span style={styles.curr_page}>{`${currentPage + 1}`}</span>
                <span style={styles.page}>{`/`}</span>
                <span style={styles.page}>{`${totalPages}`}</span>
            </div>
        );
    }
}

const styles = {
    center: {
        color: 'white'
    },
    a: {
        cursor: "pointer",
        fontSize: "2em"
    },
    page: {
        //color: "black",
        opacity: "0.5",
        fontSize: "1.5em",
        marginRight: "10px"

    },
    curr_page: {
        //color: "rgb(58, 47, 214)",
        fontSize: "1.5em",
        marginRight: "10px"

    },
    button: {
        height: "3rem",
        fontSize: "18px",
        fontWeight: 500,
        borderRadius: "5px",
        marginRight: "10px",
        color: '#1b1e21',
        background: 'rgb(255, 250, 230)',
        boxShadow: 'rgba(0, 0, 0, 0.176) 0px 3px 8px',
        display: 'block',
        marginTop: '10px',
        borderColor: '#c6c8ca',
        backgroundColor: '#d6d8d9'

    }
};

export default Pagination;