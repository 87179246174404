import React, { Component } from "react";
import { Jumbotron } from "react-bootstrap";

class News extends Component {
	render() {
		return (
			<div>
				<br></br>
				<br></br>
				<Jumbotron style={{textAlign: 'center'}}>
					<h1>News</h1>
          <div>
            2020-06-26: Ability to delete GIF from your post
          </div>
          <div>
            2020-06-26: Submit GIF with tags
          </div>
				</Jumbotron>
			</div>
		);
	}
}

export default News;