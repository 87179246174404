import React, { Component } from "react";
import { connect } from "react-redux";
import Masonry from 'react-masonry-css'
import { Link } from "react-router-dom";


import NewPost from "../../containers/NewPostFormLiteContainer";
import Item from "./Item";

import { getAllObjects } from "../../utils/objects";
import { fetchObjects } from "../../actions/object";
import { onInit, forceClose } from '../../actions/publish'
import Loading from "../libs/Loading";
import { resetAlerts } from "../../actions/publish";

import ImageNotification from "../notifications/Image";
import GIFNotification from "../notifications/GIF";


import './Section.css'
import PreviewModal from "./PreviewModal"

import Ad from "../operations/ChaturbateAd";


class Section extends Component {

  constructor(props) {
    super(props);
    this.pageRef = React.createRef();
  }

  state = {
    showPreviewModal: false,
    pid: '01dn24h7i2w21',
  }

  componentDidMount = () => {
    this.props.onMount();
    window.addEventListener('scroll', this._handleScroll, { passive: true })
  };

  componentWillUnmount = () => {
    this.props.onUnMount();
  };

	componentWillReceiveProps = nextProps => {
		if (this.props.alerts.length !== nextProps.alerts.length) {
			this.props.flushAlerts();
			nextProps.alerts.map(alert => {
				let node;
				if (alert.alertType === "post") {
					node = (
						<ImageNotification
							text={alert.text}
							href={alert.href}
						/>
					);
				} else if (alert.alertType === "COMMENT") {
					node = (
						<GIFNotification text={alert.text} href={alert.href} />
					);
				} else {
					node = `"${alert.text}"`;
				}

				return this.props.toastManager.add(node, {
					appearance: "success",
					autoDismiss: alert.autoDismiss
				});
			});
		}
	};

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _handleScroll = () => {
    var scrollPosition = window.pageYOffset; // current position
    var windowSize = window.innerHeight; // window height
    var bodyHeight = document.body.offsetHeight; // body height

    if (scrollPosition + windowSize + 50 > bodyHeight && !this.props.isFetching) {
      this.props.onLoadMore()
    }
  }

  _showPreview = (pid) => {
    this.setState({
      pid,
      showPreviewModal: true
    })
  }

  _closePreviewModal = () => {
    this.setState({
      showPreviewModal: false
    })
  }

  render() {

    const { objects, isFetching, newlyCreatedPostLink } = this.props;

    const breakpointColumnsObj = {
      default: 4,
      1100: 3,
      700: 2,
      500: 1
    };

    const items = objects.map((object) => (
      <Item
        key={object.id}
        // id={object.inReplyTo === "" ? object.id : object.inReplyTo}
        id={object.id}
        name={object.name}
        views={object.views}
        image_thumb={object.image_thumb}
        image={object.image}
        replies_count={object.replies_count}
        onClick={this._showPreview}
      />
    ));
    return (
      <div ref={this.pageRef}>
        {
          this.state.showPreviewModal &&
          <div style={styles.overlay}></div>
        }

        <div>
          {newlyCreatedPostLink && <div style={{color: 'white', paddingLeft: '10px'}}>
            <label style={{color: 'pink'}}>post created successfully:  &nbsp;</label>
            <Link to={ newlyCreatedPostLink }>https://ugifs{ newlyCreatedPostLink }</Link>
          </div>
          }
        </div>
        <NewPost />
        {
          this.state.showPreviewModal &&
          <PreviewModal
            pid={this.state.pid}
            onClose={this._closePreviewModal}
          />
        }
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {items}
        </Masonry>
        {
          isFetching &&
          <Loading />
        }
        <div style={{ position: 'fixed', right: '10px', bottom: "10px", zIndex: 10000 }}>
          <Ad />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { toastManager } = props;

  const objects = getAllObjects(state.objects, true, props.match.params.name);
  const isFetching = state.realtime.isFetching;
  let { alerts, newlyCreatedPostLink } = state.realtime;

  return {
    newlyCreatedPostLink,
    alerts,
    toastManager,
    objects,
    isFetching
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(onInit('home'))
      dispatch(fetchObjects(props.match.params.name));
    },
    onLoadMore: () => {
      dispatch(fetchObjects(props.match.params.name));
    },
    onUnMount: () => {
      dispatch(forceClose());
    },
    flushAlerts: () => {
			dispatch(resetAlerts());
		},
  };
};

var styles = {
  container: {
    width: "100%"
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#282c34",
    zIndex: 2,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);