export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS'

export const DELETE_POST         = 'DELETE_POST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'

export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'

export const COMMENT_FAILURE = 'COMMENT_FAILURE'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'

export const SHOW_COMMENT_GIF = 'SHOW_COMMENT_GIF'
export const HIDE_COMMENT_GIF = 'HIDE_COMMENT_GIF'

export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'

export const FETCH_REPLIES = 'FETCH_REPLIES'
export const FETCH_REPLIES_SUCCESS = 'FETCH_REPLIES_SUCCESS'
export const ADD_REPLY = 'ADD_REPLY'
export const ADD_REPLY_SUCCESS = 'ADD_REPLY_SUCCESS'

export const AUTH_ACTOR = 'AUTH_ACTOR'
export const AUTH_ACTOR_SUCCESS = 'AUTH_ACTOR_SUCCESS'
export const AUTH_ACTOR_FAILURE = 'AUTH_ACTOR_FAILURE'

export const ADD_ACTOR = 'ADD_ACTOR'
export const ADD_ACTOR_SUCCESS = 'ADD_ACTOR_SUCCESS'
export const FETCH_ACTOR_SUCCESS = 'FETCH_ACTOR_SUCCESS'
export const TOGGLE_UNSAFE = 'TOGGLE_UNSAFE'

export const ADD_OBJECT = 'ADD_OBJECT'
export const FETCH_OBJECT = 'FETCH_OBJECT'
export const FETCH_OBJECTS = 'FETCH_OBJECTS'
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS'
export const FETCH_OBJECT_SUCCESS = 'FETCH_OBJECT_SUCCESS'

export const FETCH_REACTIONS = 'FETCH_REACTIONS'
export const FETCH_REACTION_SUCCESS = 'FETCH_REACTION_SUCCESS'
export const FETCH_REACTIONS_SUCCESS = 'FETCH_REACTIONS_SUCCESS'


export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const RESET = 'RESET'

export const INIT_REALTIME = 'INIT_REALTIME'
export const ADD_SOCKET = 'ADD_SOCKET'
export const ADD_ONLINE_ACTORS_COUNT = 'ADD_ONLINE_ACTORS_COUNT'
export const ADD_ALERT = 'ADD_ALERT'
export const CLEAR_ALERTS = 'CLEAR_ALERTS'

export const SET_ID = 'SET_ID'

export const SHOW_PIN = 'SHOW_PIN'
export const SET_PIN = 'SET_PIN'

export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS'
export const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS'

export const SET_REACTIONS_LAST_ACCESSED_TIME = 'SET_REACTIONS_LAST_ACCESSED_TIME'

export const FETCH_NAMES = 'FETCH_NAMES'

export const ADD_NEWLY_CREATED_POST_LINK = 'ADD_NEWLY_CREATED_POST_LINK'

export const DELETE_REPLY_SUCCESS = 'DELETE_REPLY_SUCCESS'

