import {
	FETCH_ACTOR_SUCCESS,
	TOGGLE_UNSAFE,
	LOGOUT_SUCCESS
} from "../constants/ActionTypes";
import { API_HOST } from "../config/Config";

import history from "../router/history";

export function fetchActorSuccess(actor) {
	return {
		type: FETCH_ACTOR_SUCCESS,
		actorId: actor.id,
		actor
	};
}

export const toggleUNSAFE = () => (dispatch, getState) => {
    return dispatch({ type: TOGGLE_UNSAFE });
};

export const logout = () => (dispatch, getState) => {
    return dispatch({ type: LOGOUT_SUCCESS });
};


const emailVerificationAPI = (verification_id) => {
	return function(dispatch) {
		return fetch(`${API_HOST}/v1/api/email/verification`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
            body: JSON.stringify({
                verification_id,
            })
		})
		.then(response => response.json())
		.then(json => {
			if (json.status === 'success') {
				alert('email is verified. Please try to login now');
				dispatch(logout())
				history.push(`/`)
			}
		});
	};
}

export const email_verification = (verification_id) => (dispatch, getState) => {
	return dispatch(emailVerificationAPI(verification_id))
};

const fetchActorAPI = (actorId) => {
  return function (dispatch) {
    return fetch(`${API_HOST}/v1/actor/${actorId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) throw Error(response.status);
        return response.json()
      })
      .then(json => {
        const { actor_id, actor_verified, actor_active } = json[0];

        if (actor_active === false) {
          dispatch(logout());
          return false;
        }
      })
      .catch((error) => {
      });
  };
}


export const fetchActor = (actorId) => (dispatch, getState) => {
  return dispatch(fetchActorAPI(actorId));
};

