import React from 'react';

const H1 = ({ children }) => <h1 style={styles.h1}>{children}</h1>;

const styles = {
  h1: {
    fontSize: '1.875rem',
    //fontWeight: 500,
    fontWeight: 300,
    letterSpacing: '.03125rem',
    //display: 'inline-block'
    //float: 'left'
  }
};

export default H1;
