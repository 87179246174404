import React, { Component } from "react";
import FaComment from "react-icons/lib/fa/heart";

export default class Like extends Component {
    constructor(props) {
        super(props);
        this.style = { color: "red", fontSize: "1.5em" };
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <label>
                <img src={this.props.href} alt={""} style={styles.img} />
                <label style={styles.text}>{this.props.text}</label>
                <FaComment style={this.style} />
            </label>
        );
    }
}

const styles = {
    button: {
        height: "3rem",
        fontSize: "18px",
        fontWeight: 500,
        borderRadius: "5px",
        marginRight: "10px",
        //backgroundColor: "#E3FCEF",
        backgroundColor: "white",
        color: "#006644",
        boxShadow: "0 3px 8px rgba(0,0,0,0.175)"
    },
    img: {
        width: "50px",
        borderRadius: "5px",
        boxShadow: "0 3px 8px rgba(0,0,0,0.175)",
        marginRight: "10px"
    },
    text: {
        marginRight: "10px"
    }
};