import React, { Component } from "react";
import { connect } from "react-redux";

import { getTotalReplies } from "../../utils/replies";

import './Section.css'
import ViewsIcon from "../icons/ViewsIcon";
import NumCommentIcon from "../icons/NumCommentIcon";
import LinkIcon from "../icons/LinkIcon";


class Item extends Component {

  __render_post = id => {
    const url = `/i/${id}`
    var win = window.open(url, '_blank');
    win.focus();
  };

  __display_name = (name) => {
    let isVerifed = false;
    if (name.includes('verified')) {
      isVerifed = true
    }
    return (
      <span>
        {name}
        {
          isVerifed && <span><img alt="" src="https://i.imgur.com/xXA7LqW.png" style={{ width: "20px", marginLeft: "5px" }} /></span>
        }
      </span>
    )
  }

  render() {

    const { id, name, views, image, image_thumb, totalReplies, onClick } = this.props;

    let curr_image = image
    if (image_thumb) {
      curr_image = image_thumb
    }

    return (
      <div style={styles.wrapper}>
        {
          name !== 'tiktok' &&
          <img
            alt={""}
            style={styles.image}
            src={curr_image && curr_image.href}
            onClick={() => onClick(id)}
          />
        }
        {
          name === 'tiktok' &&
          <video width={400} height={600} paused onClick={() => onClick(id)}>
            <source src={curr_image && curr_image.href} type="video/mp4" />
          </video>
        }
        {
          name === 'tiktok' &&
          <>
            <div style={styles.label2}>


            </div>
            <div style={styles.labelx}>
              {
                <span >
                  <a href={`/i/${id}`} style={styles.tag2} target="_blank"><LinkIcon /></a>
                </span>
              }

            </div>
            <div style={styles.labelx}>
              {
                <span >
                  <a href={`/i/${id}`} style={styles.tag2} target="_blank"><LinkIcon /></a>
                  {
                    views !== 0 && <span style={styles.tag1}><ViewsIcon />{views}</span>
                  }
                  {totalReplies > 0 && <span style={styles.tag1}>
                    <NumCommentIcon />{totalReplies}XX
                  </span>}
                </span>
              }

            </div>
          </>
        }



        {
          name !== 'tiktok' &&
          <>
            <div style={styles.label1}>

              {totalReplies > 0 && <span style={styles.tag1}>
                <NumCommentIcon />{totalReplies}
              </span>}

              {
                views !== 0 && <span style={styles.tag1}><ViewsIcon />{views}</span>
              }

            </div>
            <div style={styles.label}>
              {name !== '' &&
                <a href={`/s/${name}`} style={styles.tag} target="_blank">

                  {this.__display_name(name)}

                </a>
              }
              {
                <span >
                  <a href={`/i/${id}`} style={styles.tag2} target="_blank"><LinkIcon /></a>
                </span>
              }

            </div>
            <div style={styles.label}>
              {name !== '' &&
                <a href={`/s/${name}`} style={styles.tag} target="_blank">

                  {this.__display_name(name)}

                </a>
              }
              {
                <span >
                  <a href={`/i/${id}`} style={styles.tag2} target="_blank"><LinkIcon /></a>
                </span>
              }

            </div>
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id, name, image, onClick, replies_count } = props
  const totalReplies = getTotalReplies(state.replies, id) || replies_count;

  return {
    id,
    name,
    totalReplies,
    image,
    onClick
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
};

const styles = {
  wrapper: {
    position: "relative",
    backgroundColor: '#282c34'
  },
  image: {
    width: "100%",
    cursor: "pointer"
  },
  label: {
    position: "absolute",
    bottom: 10,
    left: 10,
    marginBottom: "5px",
    fontSize: "15px",
  },
  labelx: {
    position: "absolute",
    bottom: 10,
    left: 40,
    marginBottom: "20px",
    fontSize: "15px",
  },
  label1: {
    position: "absolute",
    top: 10,
    left: 10,
    marginTop: "5px",
    fontSize: "15px",
  },
  label2: {
    position: "absolute",
    top: 50,
    left: 50,
    bottom: 100,
    marginTop: "5px",
    fontSize: "15px",
  },
  comment: {
    background: "white",
    padding: "10px",
    borderRadius: "10px",
    marginRight: "10px",
  },
  tag: {
    marginRight: "10px",
    background: "white",
    padding: "10px",
    borderRadius: "10px"
  },
  tag1: {
    marginRight: "10px",
    background: "white",
    padding: "10px",
    borderRadius: "10px",
    opacity: 0.7,
    fontFamily: 'monospace'
  },
  tag2: {
    marginRight: "10px",
    background: "white",
    padding: "13px",
    borderRadius: "10px",
    opacity: 1,
    fontFamily: 'monospace',
    color: '#007bff',
    cursor: 'pointer'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);