import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export default class extends Component {
  constructor(props) {
    super(props)
    //this.style = {padding: '5px', marginBottom: '10px', fontWeight: 500}
    this.style = {}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <Button style={this.style} onClick={this.props.onClick} {...this.props}>
        {this.props.children}
      </Button>
    )
  }
}
