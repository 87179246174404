
// export const API_HOST = 'http://127.0.0.1:3031';
// export const API_HOST = 'http://localhost';
export const API_HOST = 'https://www.ugifs.com';

// export const PUBLISHER_HOST = 'ws://localhost:3031';
// export const PUBLISHER_HOST = 'ws://localhost';
export const PUBLISHER_HOST = 'ws://ugifs.com';

export const EMAIL = "mailto:contact.ugifs@gmail.com";

// https://html-css-js.com/css/generator/text-shadow/

export const STORAGE_KEY = 'ug_token';

export const COMMENTS_PER_PAGE = 4;
export const REPLIES_PER_PAGE = 4;

export const DARK_MODE = true

export const DARK_BACKGROUND = 'red'
export const DARK_TITLE = '#fd4935'
export const DARK_COLOR = '#CCCAEA'
export const DARK_LINK = '#6388ed'

export const DEFAULT_BODY_WIDTH_INT = 700;
// export const DEFAULT_BODY_WIDTH_INT = 844;
export let BODY_WIDTH_INT = DEFAULT_BODY_WIDTH_INT;
export let BODY_WIDTH = `${BODY_WIDTH_INT}px`;

export const SILVER_COLOR = 'rgb(136, 136, 136)';
