import { storeSet, storeGet, storeRemove } from './store'

import { STORAGE_KEY } from "../config/Config";

export const loadState = () => {
    let user = storeGet(STORAGE_KEY)

    if (user && user.id && user.identity) {
        return user
    }

    return {
      isAuthenticated: false,
      isNSFW: 1
    }

    //return user
}

export const saveState = (id, identity, isAuthenticated, verified, gold) => {

  const user = {
    id,
    identity,
    isAuthenticated,
    verified,
    gold,
    isNSFW: 1,
  }

  storeSet(STORAGE_KEY, user)

  return user
}


export const removeState = () => {

  storeRemove(STORAGE_KEY)

  return {}
}



/*
const __guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
*/