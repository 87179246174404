import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap";
import Masonry from 'react-masonry-css'
import Autosuggest from 'react-autosuggest';

import './css/celeb-suggest.css';
import './css/Names2.css'

import Button from "../components/libs/Button";

import { fetchNames } from "../actions/names";
import { getAllNames } from "../utils/names";

const getNameSuggestions = (names, value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : names.filter(name => {
    return name.name.toLowerCase().slice(0, inputLength) === inputValue
  })
};

class Item extends Component {
  __display_name = (name) => {
    let isVerifed = false;
    if (name.includes('verified')) {
      isVerifed = true
    }
    return (
      <span>
        {name}
        {
          isVerifed && <span><img src="https://i.imgur.com/xXA7LqW.png" style={{ width: "20px", marginLeft: "5px" }} alt="" /></span>
        }
      </span>
    )
  }


  render() {
    const { name, image_thumb } = this.props;

    return (
      <div style={{ float: "left", padding: "5px" }}>
        <Link to={"/s/" + name} target="_blank" style={{ height: '20px', fontWeight: 300 }}>
          <div>
            <img alt='' src={image_thumb} style={{ width: "150px" }} />
          </div>
          <div>
            {this.__display_name(name)}
          </div>

        </Link>
      </div>
    )
  }
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;


function suggestClicked(name) {
  var win = window.open(`/s/${name}`, '_blank');
  win.focus();
}

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div onClick={() => suggestClicked(suggestion.name)} >
    <Link to={"/s/" + suggestion.name} target="_blank" style={{ height: '20px', fontWeight: 300 }}>
      <img style={styles.suggest} src={suggestion.image.href} /> <label>{suggestion.name} </label>
    </Link>
  </div>
);

class NamesModalContainer extends Component {

  state = {
    name: '',
    value: '',
    suggestions: []
  };
  componentDidMount = () => this.props.onMount();

  __handleNameChange = (name) => this.setState({ name });


  onChange = (event, { newValue }) => {
    // this.props.handleNameChange(newValue);
    // window.location.href=`/s/${newValue}`;
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    const { names } = this.props;
    this.setState({
      suggestions: getNameSuggestions(names, value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };


  __display_names2 = () => {
    let data;
    const { names, isVerified, verifiedNames } = this.props;
    data = names;

    if (isVerified) {
      data = verifiedNames;
    }

    const breakpointColumnsObj = {
      default: 6,
      1100: 6,
      700: 4,
      500: 3
    };

    const items = data.map((name) => (
      <Item
        key={name.name}
        id={name.name}
        name={name.name}
        image_thumb={name.image.href}
      />
    ));

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {items}
      </Masonry>
    )

  }

  render() {
    const { handleCloseView } = this.props;
    const { value, suggestions } = this.state;


    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Enter the first name of celebrity',
      value,
      onChange: this.onChange
    };

    return (
      <div className="static-modal" style={styles.wrapper}>
        <Modal.Dialog>
          <Modal.Header>
            <div>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <Button onClick={() => handleCloseView()} style={styles.close}>
                <img
                  alt=''
                  style={styles.closeIcon}
                  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGrBEOYRD8RwEdtCO_zo2SYtpUyReEb6IjWDwAZqb5iH8zMb8k4w"}
                />
              </Button>

            </div>
          </Modal.Header>
          <Modal.Body style={styles.container}>

            {this.__display_names2()}

          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    handleCloseView: props.handleCloseView,
    names: getAllNames(state.objects),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(fetchNames());
    },
  };
};

var styles = {
  wrapper: {
    textAlign: "left",
    fontSize: "1.3em"
  },
  container: {
    maxHeight: 'calc(80vh)',
    overflowY: 'auto',
    padding: '5px',
    fontSize: '0.9em',
    maxWidth: "900px",
    maxWidth: '100%'
  },
  closeIcon: {
    width: "29px"
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0
  },
  input: {
    height: "5rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
  suggest: {
    width: '50px'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NamesModalContainer);