import React, { Component } from "react";
import { connect } from "react-redux";
import ImageGallery from 'react-image-gallery';

import { Modal } from "react-bootstrap";
import Button from "../components/libs/Button";
import './css/hypno.css';


import { getAllObjects } from "../utils/objects";
import { getRepliesWithImagesForName } from "../utils/replies";
import { fetchReplies } from "../actions/reply";



class InfiniteHypnoModalContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      slideInterval: 500,
      objectIds: {},
      isStart: false
    }
  }

  componentDidMount = () => {
  };

  _fetchReplies = () => {
    this.props.objects.map(
      object => {
        this.props.onFetchReplies(object.id);
      }
    )
    this.setState({
      isStart: true
    })
  }

  _onSlide2 = (index) => {
    let dec = 50
    if (this.state.slideInterval <= 100) {
      dec = 10
    }
    else if (this.state.slideInterval <= 500) {
      dec = 200
    }
    else if (this.state.slideInterval < 1000) {
      dec = 500
    }
    if (index === 0) {
      if (this.state.slideInterval > 20) {
        this.setState({
          slideInterval: this.state.slideInterval - dec
        })
      } else {
        this.setState({
          slideInterval: 500
        })
      }
    }
  }

  _onSlide = (index) => {}

  customRender = (item) => {
    return (
      <div className='image-gallery-image'>
        <img
          style={{maxWidth:'100%'}}
          src={item.original}
          title={item.originalTitle}
        />
                {
          item.description &&
          <span className='image-gallery-description'>
            <span style={item.style}>
              {item.description}
            </span>
          </span>
        }
      </div>
    );
  };

  render() {
    const { objects, comments, handleCloseView } = this.props;

    let i = 0
    let images = []
    if (this.state.isStart) {
      images = comments.reduce((obj, o) => {
        obj.unshift({
          original: o.image.href,
          thumbnail: o.image.href,
          description: o.content,
        })
        const object = objects[i];
        if (object) {
          obj.unshift({
            original: object.image.href,
            thumbnail: object.image.href
          })
          i = (i+1)%objects.length
        }
  
        return obj;
      }, []);
    }

    return (
      <div className="static-modal" style={styles.container}>
        <Modal.Dialog style={{background: '#282c34', padding: 0}}>
          <Modal.Body style={{background: '#282c34', padding: 0}}>
            <Button onClick={() => handleCloseView()} style={styles.close}>
              <img
                alt=''
                style={styles.closeIcon}
                src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGrBEOYRD8RwEdtCO_zo2SYtpUyReEb6IjWDwAZqb5iH8zMb8k4w"}
              />
            </Button>
            {
              this.state.isStart === false && 
            <Button onClick={() => this._fetchReplies()} style={styles.cButton}>
              <img
                alt=''
                style={styles.cIcon}
                src="https://media2.giphy.com/media/3ohc0Y1TI0vawx60Sc/giphy.gif"
              />
               <h1 style={{color:'white'}}>CLICK ON THE COUNTDOWN TO START</h1>
            </Button>
            } 
           

            <ImageGallery
              items={images}
              showThumbnails={false}
              useBrowserFullscreen={true}
              showBullets={false}
              disableArrowKeys={true}
              showFullscreenButton={false}
              slideInterval={this.state.slideInterval}
              showPlayButton={false}
              autoPlay={true}
              showNav={false}
              slideDuration={15}
              getCurrentIndex={this.getCurrentIndex}
              onScreenChange={this._onScreenChange}
              onSlide={this._onSlide}
              onPlay={this._onPlay}
              renderItem={this.customRender}
              lazyLoad={true}
            />


          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }

  getCurrentIndex = (i) => {
  }

  _onScreenChange = (fullScreenElement) => {
  }

}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}

function shuffle(list) {
  let i = 0
  while (i < list.length - 1) {
    const index = getRndInteger(i, list.length-1);
    let temp = list[index]
    list[index] = list[i];
    list[i] = temp;
    i += 1;
  }
  return list
}

const mapStateToProps = (state, props) => {
  let objects = getAllObjects(state.objects, true, props.name);
	const comments = getRepliesWithImagesForName(state.replies, state.objects, props.name);
  return {
    objects: shuffle(objects),
    comments: shuffle(comments),
    handleCloseView: props.handleCloseView,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
    },
    onFetchReplies: (id) => {
      dispatch(fetchReplies(id));
    }
  };
};

var styles = {
  left: {
    textAlign: 'left'
  },
  container: {
    width: "800",
    background: "#282c34"
  },
  closeIcon: {
    width: "29px"
  },
  cIcon: {
    width: "100%"
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 99999,
  },
  cButton: {
    zIndex: 99999,
    width: '100%',
    background: '#282c34'
  },
  input: {
    height: "5rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
  button: {
    width: '100%'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InfiniteHypnoModalContainer);