import React, { Component } from 'react'
import Icon from 'react-icons/lib/fa/eye'

export default class ViewsIcon extends Component {
  constructor(props) {
    super(props)
    this.style = {}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <Icon />
    )
  }
}
