import React, { Component } from "react";
import { connect } from "react-redux";

import Post from "./Post";
import Pagination from "../post/Pagination";

import { getRepliesWithImages, getRandomReply } from "../../utils/replies";

import { showPin } from "../../actions/pin"
import { onLikeObject, onRequestForMore } from "../../actions/object"

class PostHolder extends Component {
	state = {
		currentPage: 0,
		showComments: true,
		showSettings: false,
	};

	render() {
		const { post, comment, comments, reactions, showPin } = this.props;
		return (
			<div style={{ overflow: "hidden" }}>
				<Post
					object={post} 
					comment={comment}
					comments={comments}
					reactions={reactions}
					showPin={showPin}
					showComments={this.state.showComments} 
				/>
			</div>
		);
	}


	componentWillReceiveProps = (nextProps) => {
  	}

	__renderPagination = totalPages => {
		if (totalPages <= 1) {
			return null;
		}

		return (
			<Pagination
				currentPage={this.state.currentPage}
				totalPages={totalPages}
				onPreviousClick={this.goToPrevious}
				onNextClick={this.goToNext}
			/>
		);
	};

	__onLike = () => {
		this.props.onLike(this.props.id, this.props.post['image']['href'])
	};

	__onRequestForMore = () => {
		this.props.onRequestForMore(this.props.id, this.props.post['image']['href'])
	};

	__onToggleShowSettings = () => {
		this.setState({
			showSettings: !this.state.showSettings
		});
	};

	setCurrentPage = currentPage => {
		this.setState({
			currentPage
		});
	};

	setTranslateValue = translateValue => {
		this.setState({
			translateValue
		});
	};
}

const mapStateToProps = (state, props) => {
	const { id } = props;

	const post = state.objects[id] || {};
	const comment = getRandomReply(state.replies, id);

	const comments = getRepliesWithImages(state.replies, id);

	const userId = state.user.id;
	const actorId = post.attributedTo;

	return {
		id,
		post,
		comment,
		comments,
		isAdmin: userId === actorId
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		showPin: (top, left) => {
			dispatch(showPin(top, left, props.id))
		},
		onLike: (id, href) => {
			dispatch(onLikeObject(id, href))
		},
		onRequestForMore: (id, href) => {
			dispatch(onRequestForMore(id, href))
		},
		onFetchRandomReaction: () => {

		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PostHolder);