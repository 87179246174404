import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { FormGroup, FormControl } from "react-bootstrap";
import { RESET } from "../constants/ActionTypes";

import {
	ButtonToolbar,
	Modal
} from "react-bootstrap";

import { addObjectLink } from "../actions/object.js";

import Alert from "../components/libs/Alert";
import Info from "../components/libs/Info";
import Warning from "../components/libs/Warning";
import Loading from "../components/libs/Loading";
import Button from "../components/libs/Button";


function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function get_url_extension( url ) {
	return url.split(/[#?]/)[0].split('.').pop().trim();
}

class Confirm extends Component {
	state = {
		href: '',
		previewSrc: null
	}

	componentDidMount = () => {
		// this.__loadPreview(this.props.file)
	}

	loadPreview = () => {
		if (!isValidHttpUrl(this.href.value)) {
			alert('Not a valid url');
			this.href.value = '';
			return;
		}
		const extension = get_url_extension(this.href.value);
		if (extension !== 'mp4' && extension !== 'webm') {
			alert('File should be either webm or mp4');
			this.href.value = '';
			return;
		}
		this.setState({
			href: this.href.value
		});
	}

	render() {
		const {
			onSubmit, onCancelConfirm
		} = this.props;
		const { previewSrc } = this.state

		return (
			<div className="static-modal" >
				<Modal.Dialog>
					<Modal.Body >
          <FormGroup>
						<FormControl
              inputRef={ref => { this.href = ref; }}
              style={styles.input}
              type={"text"}
							placeholder={"Tiktok video url ending with .mp4 or .webm"}
							onChange={() => this.loadPreview()}
            />
						
					</FormGroup>	
					<ButtonToolbar >
							<Button
								bsStyle="primary"
								onClick={e =>
									onSubmit(this.href.value)
								}
							>
								{"submit"}
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button
								bsStyle="danger"
								onClick={e =>
									onCancelConfirm()
								}
							>
								{"cancel"}
							</Button>							
						</ButtonToolbar>		
						<br/>	
						Check <Link target="_blank" to='/s/tiktok'>Tiktok</Link> to look for inspiration.
						<br/>
						<Link target="_blank" to={{ pathname: 'https://imgur.com/'}}>https://imgur.com</Link> links are only allowed. 
						Please upload your video to imgur and add the link here.					
						<br/>
						<br/>
						{
							this.state.href &&
							<video controls autoPlay loop width={400}>
							<source src={this.state.href} type="video/mp4"/>            
							</video>
						}
					</Modal.Body>
				</Modal.Dialog>
			</div>
		)
	}
}

class NewTiktokFormLiteContainer extends Component {
	state = {
		loading: false,
		isNSFW: [1],
		isOpen: [],
		showConfirm: false,
		previewSrc: null,
	};

	componentDidMount = () => this.props.onMount();

	onDrop = () => {
		this.setState({
			showConfirm: true
		})
	};

	onSubmit = (href) => {
		if (href === '') {
			alert('Please enter the tiktok video url')
			return;
		}

		this.props.onSubmit(href);
		this.props.handleCloseView();
	}

	onCancelConfirm = () => {
		this.setState({
			showConfirm: false
		})
	}

	render() {
		const { isFetching, errorMessage, isAuthenticated, verified } = this.props;
		const { showConfirm } = this.state;

		return (
			<div>
				{errorMessage !== "" ? <Alert>{errorMessage}</Alert> : null}

				<div>
					<FormGroup style={styles.container}>
						<div onClick={this.onDrop}>
							Click to upload tiktok video link
						</div>
						{
							this.props.show &&
							<Confirm
								onCancelConfirm={this.props.handleCloseView}
								onSubmit={this.onSubmit}
								file={this.file}
							/>
						}
					</FormGroup>
					{isFetching ? <Loading /> : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { isAuthenticated, verified } = state.user

	const { isFetching, errorMessage } = state.loading;
	return {
		isFetching,
		errorMessage,
		isAuthenticated,
		verified,
		show: props.show,
		handleCloseView: props.handleCloseView
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSubmit: (href) => {
			dispatch(addObjectLink(href, 'tiktok'));
		},
		onMount: () => {
			dispatch({ type: RESET });
		}
	};
};

const styles = {
	container: {
		padding: "4px",
		marginBottom: "4px",
		paddingBottom: 0
	},
	button: {
		marginLeft: '1rem'
	},
	preview: {
		width: "125px",
		height: "148px",
		float: "right",
		position: "absolute",
		right: "16px",
		top: "16px"
	},
	info: {
		//marginLeft: "1rem"
		widht: '100%'
	},
	dropzone: {
		width: "100%",
		borderWidth: "2px",
		borderColor: "rgb(102, 102, 102)",
		borderStyle: "dashed",
		borderRadius: "5px",
		textAlign: "center",
		opacity: "0.6",
		cursor: "pointer",
		marginTop: "40px",
		color: "white"
	},
	toolbar: {
		position: 'absolute',
		bottom: '1rem',
		right: '16px'
	},
	nsfwLabel: {
		position: 'absolute',
		bottom: '10px',
		left: '160px'
	},
	warning: {
		display: 'block',
		textAlign: 'center',
		marginLeft: '4px',
		marginRight: '4px',
		marginBottom: '4px',
		fontWeight: 400,
	},
  input: {
    height: "3rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(
	NewTiktokFormLiteContainer
);
