// import { PUBLISHER_HOST } from "../config/Config";
import {
	// INIT_REALTIME,
	ADD_ALERT,
	CLEAR_ALERTS
} from "../constants/ActionTypes";

// import { addReplySuccess } from "./reply"
// import { fetchObjectSuccess, deleteObjectSuccess } from "./object"
// import { fetchOnlineActorsSuccess } from "./realtime"
// import { logout } from "./login"
import { onInitSocket } from './socket';

let socket

export function addAlert(alert) {
	let text = ""
	let alertType = ""
	let autoDismiss = false
	let href = ""
	if (alert.type === 'comment') {
		text = " someone added new GIF"
		alertType = "comment"
		href = alert.inReplyToHref
	} else if (alert.type === 'POST') {
		text = "new image added"
		alertType = "post" 
		href = alert.image.href
		autoDismiss = true
	} else if (alert.type === 'request') {
		text = " Someone is requesting for more"
		alertType = "request"
		href = alert.href
	} else if (alert.type === 'like') {
		text = " Someone liked"
		alertType = "like"
		href = alert.href
	} else if (alert.type === 'gifplease') {
		text = "OP is requesting for more GIF"
		alertType = "gifplease"
	} else {
		text = alert.text
		alertType = ""
		autoDismiss = true
	}
	return {
		type: ADD_ALERT,
		text,
		alertType,
		autoDismiss,
		href,
	};
}

export function clearAlerts() {
	return {
		type: CLEAR_ALERTS,
	};
}

export const onInit = (id) => (dispatch, getState) => {
	if (id === undefined) {
		return;
	}
	dispatch(onInitSocket());	
	
	/*socket = new WebSocket(PUBLISHER_HOST+`/${id}`)

	dispatch({type: INIT_REALTIME});
	dispatch(__onConnect())
	dispatch(__onError())
	dispatch(__onClose())
	dispatch(__onMessage())*/
}

/*
const __onConnect = () => (dispatch, getState) => {
	socket.onopen = () => {
		//console.log("connected")
		setInterval(__ping, 30000);
	};
}

const __onError = () => (dispatch, getState) => {
	socket.onerror = () => {
		//console.log("Error")
	};
}

const __onClose = () => (dispatch, getState) => {
	socket.onclose = () => {
		//console.log("Close")
	};
}


export const forceClose = () => (dispatch, getState) => {
	// console.log('closing')
	// socket.close()
}
*/
export const forceClose = () => (dispatch, getState) => {
	// console.log('closing')
	// socket.close()
}

/*
const __onMessage = () => (dispatch, getState) => {
	socket.onmessage = (msg) => {
		//console.log("onMessage :")
		dispatch(__evaluateMessage(msg.data))
	};
}
*/

export const sendMessage = (msg) => (dispatch, getState) => {
	if (Object.keys(msg).length === 0) {
		return
	}
	msg['identity'] = getState().user.identity
	socket.send(JSON.stringify(msg));
}

/*
const __evaluateMessage = (data) => (dispatch, getState) => {
	//console.log(data)
	data = JSON.parse(data);

	const { id, identity } = getState().user

	if (data.type === 'ping') {
		__pong()
		return
	}


	if (data.type === 'comment') {
		data.image.unseen = true;
		dispatch(addReplySuccess(data))
	} else if (data.type === 'POST') {
		dispatch(fetchObjectSuccess(data))
	} else if (data.type === 'count') {
		console.log(data);
		dispatch(fetchOnlineActorsSuccess(data))
	} else if (data.type === 'delete') {
		dispatch(deleteObjectSuccess(data.id));
		//history.push('/')
	} else if (data.type === 'logout') {
		if (id === data['attributedTo']) {
			dispatch(logout());
		}
		//history.push('/')
	}


	if (data.type === 'comment') {
		//data.inReplyToHref = getState().objects[data.inReplyTo].image.href
		data.inReplyToHref = data.image.href
	}

	if (identity === data['identity']) {
		//console.log('ignoring')
		return;
	}

	const realtime_id = getState().realtime.id
	if (data.type === 'comment' && data['id'] !== realtime_id) {
		return
	}


	dispatch(addAlert(data))
}
*/

export const resetAlerts = () => (dispatch, getState) => {
	dispatch(clearAlerts())
}

/*
let tm;
const __ping = () => {
	const msg = {
		type: 'ping'
	}
	//console.log('ping')
	socket.send(JSON.stringify(msg));
	tm = setTimeout(function () {
           /// ---connection closed ///
    }, 5000);
}

const __pong = () => {
	//console.log('pong')

    clearTimeout(tm);
}
*/
