import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal } from "react-bootstrap";
import { fetchReactions } from "../../actions/reactions";
import { incrViews } from "../../actions/object";
import { fetchReplies } from "../../actions/reply";

import JuxtaPostWithToasts from "../../containers/JuxtaPostWithToastsContainer"
import TrainWithToasts from "../../containers/TrainWithToastsContainer"

import './preview.css';


class PreviewModal extends Component {

  state = {
    event: 'hypno'
  }

  constructor(props) {
    super(props)
    let event = '';
    if (props.onlyHypnotize) {
      event = 'hypno';
    } else {
      event = 'story';
    }
    this.state = {
      event: event
    }
  }

  componentDidMount = () => {
    this.props.onMount();
  }

  _onChangeEvent = () => {
    let event = '';
    if (this.state.event === 'hypno') {
      event = 'story';
    } else {
      event = 'hypno';
    }
    this.setState({
      event
    })
  }

  _settingStyle = () => {
    return {
      position: 'absolute',
      zIndex: 100000,
      left: 0,
      background: 'white'
    }
  }

  _settingStyle2 = () => {
    return {
      position: 'absolute',
      zIndex: 100000,
      background: '#282c34',
      border: 0,
      margin: '10px'
    }
  }

  _settingButtonStyle = () => {

  }


  render() {

    const { pid, onClose } = this.props
    const { event } = this.state;


    return (
      <div className="preview static-modal" style={styles2.wrapper} >
        <Modal.Dialog>
          <Modal.Header style={this._settingStyle2()}>
            <div style={styles.links}>
              {
                event === 'story' &&
                <div onClick={() => this._onChangeEvent()} style={styles2.default}>
                  Hypnotize
                                </div>
              }
              {
                event === 'hypno' &&
                <div onClick={() => this._onChangeEvent()} style={styles2.default}>
                  Normal
                                </div>
              }

              <div onClick={() => onClose()} style={styles2.close}>close</div>
            </div>
          </Modal.Header>
          <Modal.Body style={styles2.container}>

            {
              event === 'story' && <JuxtaPostWithToasts pid={pid} />
            }
            {
              event === 'hypno' && <TrainWithToasts pid={pid} />
            }
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { pid, onClose } = props
  let onlyHypnotize = true;

  return {
    pid,
    onClose,
    onlyHypnotize
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      const { pid } = props
      dispatch(fetchReplies(pid));
      dispatch(fetchReactions());
      dispatch(incrViews(pid))
    }
  };
};

var styles = {
  wrapper: {
    width: "100%"
  },
  container: {
    width: "100%"
  },
  selected: {
    cursor: 'pointer',
    width: '100px',
    padding: '5px',
    background: '#28a745',
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
    textAlign: 'center',
    border: '1px solid',
    borderRadius: '3px',
    fontWeight: 500,
    marginBottom: '0.2em'

  },
  close: {
    cursor: 'pointer',
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    textAlign: 'center',
    border: '1px solid',
    borderRadius: '3px',
    fontWeight: 200,
    padding: '5px',
  },
  center: {
    maxWidth: "700px",
    margin: "auto"
  },
  links: {
  },
  default: {
    cursor: 'pointer',
    // width: '100px',
    // padding: '5px',
    color: '#383d41',
    textAlign: 'center',
    // border: '1px solid',
    // borderRadius: '3px',
    fontWeight: 200,
    // marginBottom: '0.2em'
  }
};


var styles2 = {
  wrapper: {
    width: "100%"
  },
  container: {
    width: "100%"
  },
  selected: {
    cursor: 'pointer',
    width: '100px',
    padding: '5px',
    color: 'white',
    textAlign: 'center',
    border: '1px solid',
    borderRadius: '3px',
    fontWeight: 500,
    marginBottom: '0.2em',
    display: 'inline-block',
    marginLeft: '10px'


  },
  close: {
    cursor: 'pointer',
    // borderColor: 'white',
    textAlign: 'center',
    // border: '1px solid',
    // borderRadius: '3px',
    fontWeight: 200,
    // padding: '5px',
    display: 'inline-block',
    color: 'white',
    // marginLeft: '10px'
    padding: '10px'

  },
  center: {
    maxWidth: "700px",
    margin: "auto"
  },
  links: {
  },
  default: {
    cursor: 'pointer',
    color: 'white',
    textAlign: 'center',
    // width: '100px',
    // padding: '5px',
    // border: '1px solid',
    // borderRadius: '3px',
    fontWeight: 200,
    // marginBottom: '0.2em',
    display: 'inline-block',
    // marginLeft: '10px',
    padding: '10px',
    borderRight: '1px solid'

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);