import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, FormControl, Table } from "react-bootstrap";
import Button from "../components/libs/Button";

import { addFeedbackObject, fetchFeedbackObjects } from "../actions/object";
import { getFeedbackObjects } from "../utils/objects";


class ContactModalContainer extends Component {

  componentDidMount = () => this.props.onMount();

  render() {
    const { feedbacks, handleCloseView, user, onSubmit } = this.props;

    return (
      <div className="static-modal" style={styles.container}>

        <h3>Feedback</h3>

        <Button onClick={() => handleCloseView()} style={styles.close}>
          <img
            alt=''
            style={styles.closeIcon}
            src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGrBEOYRD8RwEdtCO_zo2SYtpUyReEb6IjWDwAZqb5iH8zMb8k4w"}
          />
        </Button>
        <FormGroup style={{ marginTop: '30px' }}>
          <FormControl
            componentClass="textarea"
            rows="3"
            inputRef={ref => { this.text = ref; }}
            style={styles.input}
            type={"text"}
            placeholder={"please write your feedback"}
          />
        </FormGroup>
        <Button
          bsStyle="primary"
          onClick={e => onSubmit(this.text.value, user.id)}
          style={styles.button}
        >
          {"send"}
        </Button>
        <br /><br />

        <div style={{ textAlign: 'center' }}>
          you can also email to <label style={{ color: 'blue' }}> contact.ugifs@gmail.com</label>
        </div>

        <Table striped>
          <thead>
            <tr>
              <th style={styles.left}>Feedback</th>
            </tr>
          </thead>

          <tbody>
            {
              feedbacks.map(feedback => {
                return (
                  <tr>
                    <td style={styles.left}>{feedback.content}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const feedbacks = getFeedbackObjects(state.objects);

  return {
    feedbacks,
    handleCloseView: props.handleCloseView,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (text, user_id) => {
      text = text.trim()
      if (text === "") {
        alert("Please enter text")
        return
      }

      /*if (user_id) {
        // text = text + " - user_id: " + user_id;
      } else {
        if (text.search("@") === -1) {
          // alert("Please enter email id along with the feedback or login to the website")
          // return
        }
      }*/

      dispatch(addFeedbackObject(text))
      // window.location.reload(true);
      // props.handleCloseView();
    },
    onMount: () => dispatch(fetchFeedbackObjects())
  };
};

var styles = {
  left: {
    textAlign: 'left'
  },
  container: {
    backgroundColor: 'white',
    padding: '20px',
    marginTop: '100px'
  },
  closeIcon: {
    width: "29px"
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0
  },
  input: {
    height: "5rem",
    borderRadis: "5px",
    paddingLeft: "5px"
  },
  button: {
    width: '100%'
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModalContainer);