import React, { Component } from 'react'
import Iframe from 'react-iframe'

class ChaturbateAd extends Component {
	constructor(props) {
		super(props)
		const width = window.innerWidth
		this.show = true
		this.url = 'https://chaturbate.com/affiliates/in/?tour=dTm0&campaign=sQVLe&track=embed&bgcolor=transparent&disable_sound=1&embed_video_only=1&target=_parent'
		if (width > 700) {
			this.width = '350px'
			this.height = '200px'
		} else if (width < 643 && width > 500 ) {
			this.width = '400px'
			this.height = '200px'
			this.url = 'https://chaturbate.com/affiliates/in/?tour=dTm0&campaign=sQVLe&track=embed&bgcolor=transparent&disable_sound=1&embed_video_only=1&target=_parent'
		} else {
			this.show = false
		}
	}


  render() {		
    return (
      <div style={{marginLeft: '-43px'}}>
			{ this.show === true ?
        <Iframe
					
          url={this.url}
          position='inherit'
					width={this.width}
			 		height={this.height}
          border='none' /> : null }
      </div>
		)
	}
}

export default ChaturbateAd
