import React, { Component } from "react";


class PostImage extends Component {
    render = () => {

        //this.props = {
        //    image_url: 'https://i.redd.it/otnog7o1pgs21.jpg'
        //}

        return (
            <div>
                <img
                    style={styles.imgContainer}
                    src={`${this.props.image_url}`}
                    alt=""
                />
            </div>
        );
    };
}

const styles = {
    imgContainer: {
        padding: 0,
        display: "block",
        margin: "0 auto",
        maxHeight: "100%",
        maxWidth: "100%",
        userDrag: "none",
        userSelect: "none",
        MozUserSelect: "none",
        WebkitUserDrag: "none",
        WebkitUserSelect: "none",
        MsUserSelect: "none",
        backgroundColor: "rgba(0, 0, 0, 0.05)"
    },
};

export default PostImage;