import React, { Component } from "react";
import { connect } from "react-redux";
import Masonry from 'react-masonry-css'

import NewPost from "../../containers/NewPostFormLiteContainer";
import Item from "./Item";

import { getAllObjects } from "../../utils/objects";
import { fetchObjectsAttributedTo } from "../../actions/object";
import { fetchUnverifiedObjects } from "../../actions/object";
import Loading from "../libs/Loading";

import './Section.css'
import PreviewModal from "./PreviewModal"

import Ad from "../operations/ChaturbateAd";


class Unverified extends Component {

  constructor(props) {
    super(props);
    this.pageRef = React.createRef();
  }

  state = {
    showPreviewModal: false,
    pid: '01dn24h7i2w21',
  }

  componentDidMount = () => {
    this.props.onMount();
    window.addEventListener('scroll', this._handleScroll, { passive: true })
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _handleScroll = () => {
    var scrollPosition = window.pageYOffset; // current position
    var windowSize = window.innerHeight; // window height
    var bodyHeight = document.body.offsetHeight; // body height

    if (scrollPosition + windowSize + 50 > bodyHeight && !this.props.isFetching) {
      // this.props.onLoadMore()
    }
  }

  _showPreview = (pid) => {
    this.setState({
      pid,
      showPreviewModal: true
    })
  }

  _closePreviewModal = () => {
    this.setState({
      showPreviewModal: false
    })
  }

  render() {

    const { objects, isFetching, isAdmin, isGold, isAuthenticated } = this.props;

    if (isAdmin === false) {
      /*return (
        <p style={{color: 'white', textAlign: 'center'}}>Only admin can view this page</p>
      )*/
    }

    if (isAuthenticated !== true) {
      return (
        <p style={{color: 'white', textAlign: 'center'}}>Temporary access to login users only</p>
      )
    }

    if (isGold !== true) {
      /*return (
        <p style={{color: 'white', textAlign: 'center'}}>Only users with gold access can view this page. Please contact admin at contact.ugifs@gmail.com</p>
      )*/
    }

    const breakpointColumnsObj = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1
    };

    const items = objects.map((object) => (
      <Item
        key={object.id}
        id={object.id}
        name={object.name}
        views={object.views}
        image_thumb={object.image_thumb}
        image={object.image}
        replies_count={object.replies_count}
        onClick={this._showPreview}
      />
    ));
    return (
      <div ref={this.pageRef}>
        {
          this.state.showPreviewModal &&
          <div style={styles.overlay}></div>
        }

        <NewPost />
        {
          this.state.showPreviewModal &&
          <PreviewModal
            pid={this.state.pid}
            onClose={this._closePreviewModal}
          />
        }
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {items}
        </Masonry>
        {
          isFetching &&
          <Loading />
        }
        <div style={{ position: 'fixed', right: '10px', bottom: "10px", zIndex: 10000 }}>
          <Ad />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // const objects = getAllObjectsAttributedTo(state.objects, props.match.params.actorId);
  const objects = getAllObjects(state.objects, false, undefined);
  const isFetching = state.realtime.isFetching;
  const userId = state.user.id;

  return {
    objects,
    isFetching,
    isAdmin: userId === props.match.params.actorId,
    isGold: state.user.gold === true,
    isAuthenticated: state.user.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(fetchUnverifiedObjects(undefined));
    },
    onLoadMore: () => {
      dispatch(fetchUnverifiedObjects(undefined));
    },
  };
};

var styles = {
  container: {
    width: "100%"
  },
  overlay: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#282c34",
    zIndex: 2,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unverified);