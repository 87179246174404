import React, { Component } from "react";
import { connect } from "react-redux";
import Masonry from 'react-masonry-css'

import Item from "./Item";

import { getRepliesWithContent } from "../../utils/replies";
import { fetchRepliesWithContent } from "../../actions/reply";
import Loading from "../libs/Loading";

import './Section.css'


class Feed extends Component {

  constructor(props) {
    super(props);
    this.pageRef = React.createRef();
  }

  componentDidMount = () => {
    this.props.onMount();
    window.addEventListener('scroll', this._handleScroll, { passive: true })
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _handleScroll = () => {
    var scrollPosition = window.pageYOffset; // current position
    var windowSize = window.innerHeight; // window height
    var bodyHeight = document.body.offsetHeight; // body height

    if (scrollPosition + windowSize + 50 > bodyHeight && !this.props.isFetching) {
      this.props.onLoadMore()
    }
  }

  render() {

    const { comments, isFetching } = this.props;

    // console.log(comments);
    return this.render_simple(comments);

    const breakpointColumnsObj = {
      default: 1,
      1100: 1,
      700: 1,
      500: 1
    };

    const items = comments.map((comment) => (
      <Item
        key={comment.id}
        id={comment.id}
        content={comment.content}
        image={comment.image}
      />
    ));
    return (
      <div ref={this.pageRef}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {items}
        </Masonry>
        {
          isFetching &&
          <Loading />
        }
      </div>
    );
  }

  render_simple(comments) {
    const items = comments.map((comment) => (
      <Item
        key={comment.id}
        id={comment.id}
        content={comment.content}
        image={comment.image}
      />
    ));
    return (
      <div>
        {items}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {

  const comments = getRepliesWithContent(state.feeds);
  const isFetching = state.realtime.isFetching;

  return {
    comments,
    isFetching
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(fetchRepliesWithContent());
    },
    onLoadMore: () => {
      dispatch(fetchRepliesWithContent());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);