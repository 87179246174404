import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, InputGroup, FormControl } from "react-bootstrap";
import { RESET } from "../../constants/ActionTypes";

import { addGif } from "../../actions/moderate.js";

import Button from "../libs/Button";
import Alert from "../libs/Alert";
import Loading from "../libs/Loading";

class ModerateWebM extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			href: "",
			race:props.match.params.race
		};
	}

	componentDidMount = () => this.props.onMount();

	onSubmit = () => {
		this.props.onSubmit(this.state.href, this.state.race);
	};

	__handleHrefChange = e => {
		this.setState({ href: e.target.value });
	};

	onChangeValue= (event) => {
		console.log(event.target.value);
		this.setState({
			race: event.target.value
		});
	}

	render() {
		const { isFetching, errorMessage } = this.props;
		return (
			<div>
				<br/>
				<h1 style={{color: 'white', fontWeight:100}}>
					UGIFS - Submit <span style={{color: 'red'}}>{this.state.race}</span> GIFs
				</h1>
				{errorMessage !== "" ? <Alert>{errorMessage}</Alert> : null}

				<div style={{ marginTop: "30px" }}>
					<FormGroup>
						<InputGroup>
							<FormControl
								type="text"
								value={this.state.href}
								onChange={this.__handleHrefChange}
								placeholder="GIF link eg: https://......gif"
							/>
							<Button
								bsStyle={"primary"}
								style={{ width: '100px', fontSize: '20px' }}
								onClick={() => this.onSubmit()}
							>
								{"submit"}
							</Button>
						</InputGroup>
						<br/>
						<img src={this.state.href} style={{ width: '100%' }} />
					</FormGroup>
					<h3>
						Thanks for helping.
						<br/><br/>
						The submitted GIF will go through a verification process before being added to the website.
						This will take 1-2 days from the time of submission.
					</h3>


					{isFetching ? <Loading /> : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { isFetching, errorMessage } = state.loading;
	return {
		isFetching,
		errorMessage
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSubmit: (href, race) => {
			if (href.trim() === '') {
				alert('Link to GIF can not be empty');
				return;
			}
			dispatch(addGif(href, race));
		},
		onMount: () => {
			dispatch({ type: RESET });
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerateWebM);
