import openSocket from 'socket.io-client';
import {
	INIT_REALTIME,
	ADD_ALERT,
	CLEAR_ALERTS
} from "../constants/ActionTypes";
import { addReplySuccess } from "./reply"
import { fetchObjectSuccess, deleteObjectSuccess } from "./object"
import { logout } from "./login"
import { PUBLISHER_HOST } from "../config/Config";


const socket = openSocket(PUBLISHER_HOST);

export const onInitSocket = () => (dispatch, getState) => {
	dispatch({type: INIT_REALTIME});
	dispatch(__onMessage());
}

const __onMessage = () => (dispatch, getState) => {
  socket.on('message', function(data){
    dispatch(__evaluateMessage(data))
  });
}

export const sendMessage = (msg) => (dispatch, getState) => {
	if (Object.keys(msg).length === 0) {
		return
	}
	msg['identity'] = getState().user.identity
	socket.emit('message', JSON.stringify(msg));
}

const __evaluateMessage = (data) => (dispatch, getState) => {
	data = JSON.parse(data);

	const { id, identity } = getState().user

	if (data.type === 'ping') {
		return
	}

	if (data.type === 'COMMENT') {
		data.image.unseen = true;
		dispatch(addReplySuccess(data))
	} else if (data.type === 'POST') {
		dispatch(fetchObjectSuccess(data))
	} else if (data.type === 'delete') {
		dispatch(deleteObjectSuccess(data.id));
	} else if (data.type === 'logout') {
		if (id === data['attributedTo']) {
			dispatch(logout());
		}
	}

	if (data.type === 'COMMENT') {
		data.inReplyToHref = data.image.href
	}

	if (identity === data['identity']) {
		return;
	}

	const realtime_id = getState().realtime.id
	if (data.type === 'COMMENT' && data['id'] !== realtime_id) {
		return
	}

	dispatch(addAlert(data));
}

export function addAlert(alert) {
	let text = ""
	let alertType = ""
	let autoDismiss = false
	let href = ""
	if (alert.type === 'COMMENT') {
		text = "someone added new GIF"
		alertType = "comment"
		href = alert.inReplyToHref
		autoDismiss = true
	} else if (alert.type === 'POST') {
		text = "new image added"
		alertType = "post" 
		href = alert.image.href
		autoDismiss = true
	} else {
		text = alert.text
		alertType = ""
		autoDismiss = true
	}

	return {
		type: ADD_ALERT,
		text,
		alertType,
		autoDismiss,
		href,
	};
}

export function clearAlerts() {
	return {
		type: CLEAR_ALERTS,
	};
}

export function subscribeToTimer(cb) {
  socket.on('timer', timestamp => cb(null, timestamp));
  socket.emit('subscribeToTimer', 1000);
}
