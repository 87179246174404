import React, { Component } from "react";
import { Link } from "react-router-dom";

import H1 from "../libs/H1";
import './logo-icon.css';

//const LOGO_LINK = "https://media.giphy.com/media/CpmS8pRpOOD4Y/giphy.gif";

export default class LogoIcon extends Component {
  constructor(props) {
    super(props);
    this.style = {};
    if (props.style) {
      this.style = { ...this.style, ...props.style };
    }

    if (this.props.name !== undefined) {
      document.title = `${this.props.name} PICTURES`
    }
  }

  __display_name2 = (name) => {

    if (name === undefined) {
      return <div>
      <h1 className="neon-h1">UGIFS - CELEB FETISH</h1>
      <br></br>
      <Link to='/about'>about</Link><br/>
      {/*<label style={{color: 'red', fontSize: 'small'}}>News: Non verfied post available for viweing to public for first 12 hours</label>*/}
      <label style={{fontSize: 'medium', fontFamily: 'Exo 2'}}>
      {/*News: Non-verified posts created by a Gold user can be viewed by anyone. 
      <br/>Email with your userId to become a gold user. Create multiple posts before emailing.*/}
      </label>
      <br/>
      {/*<label style={{color: 'red', fontSize: 'small'}}>
      News: Tiktok feature: <Link to='/s/tiktok'>Tiktok</Link>
    </label>*/}
      {/*<br></br>
      <label style={{color: 'red', fontSize: 'small'}}>
      News: Unverified feature: <Link to='/unverified'>Unverified Posts</Link>
      </label>*/}
      </div>
    }

    let isVerifed = false;
    if (name.includes('verified')) {
      isVerifed = true
    }

    let isJournal = false
    if (name.includes('@')) {
      isJournal = true
    }

    if (isJournal) {
      return (
        <div>
          <span>
            {
              <h1 className="neon-h1">{name}</h1>
            }
          </span>
          <br></br>
      <Link to='/about'>about</Link><br></br>
        </div>
      )
    }

    if (isVerifed === false) {
      return (
        <div>
          {/*<h1 className="neon-h1">
            <img alt='' src="https://i.ugifs.com/969c10ce-218.jpg" style={{ width: '10px', borderRadius: '10px' }} />
            </h1>*/}
          <span>

            {
              <h1 className="neon-h1">{name}</h1>
            }
          </span>
        </div>
      )
    }

    return (
      <div>
        <span>

          {
            isVerifed && <h1 className="neon-h1">{name}
              <img alt='' src="https://i.imgur.com/xXA7LqW.png" style={{ width: "20px", marginLeft: "5px" }} />
            </h1>
          }
        </span>
      </div>
    )
  }

  __display_name = (name) => {

    if (name === undefined) {
      return <span>UGIFS - {"CELEBRITY PICTURES & FETISH"}</span>
    }

    let isVerifed = false;
    if (name.includes('verified')) {
      isVerifed = true
    }

    if (isVerifed === false) {
      return (
        <span>{name}</span>
      )
    }

    return (
      <div>
        <span>
          {name}
          {
            isVerifed && <span><img alt='' src="https://i.imgur.com/xXA7LqW.png" style={{ width: "20px", marginLeft: "5px" }} /></span>
          }
        </span>
        <div style={{ fontSize: '0.5rem' }}>subscribe and unlock more</div>
      </div>
    )
  }

  render3() {
    return <h1 className="neon-h1">UGIFS - CELEB FETISH</h1>
  }

  render5() {
    return <h1 className="neon-h1">UGIFS - CELEB FETISH</h1>
  }

  render() {
    return (
      <div style={this.style}>
        <H1 style={styles.title}>
          <Link to="/" style={styles.container}>
            {this.__display_name2(this.props.name)}
          </Link>
        </H1>
      </div>
    );
  }
}

const styles = {
  container: {
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "xx-large",
    //fontWeight: 200,
    textDecoration: "none",
    fontFamily: "monospace",
    color: "white"
  },
  title: {
    //verticalAlign: "bottom",
    //marginLeft: "10px"
  },
  logo: {
    width: "50px",
    marginBottom: "20px"
  }
};