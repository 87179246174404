import { Component } from 'react'
import { connect } from 'react-redux'

import { onInit } from '../actions/publish'

class Realtime extends Component {
  constructor(props) {
    super(props)

    // Initial page load - only fired once
    this.props.onMount()
  }

  render() {
    return null
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => dispatch(onInit())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  Realtime
);
