import { SHOW_PIN } from "../constants/ActionTypes";

const initialState = {
  show: false,
  left: "0%",
  top: "0%",
  inReplyTo: null
};

export const pin = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PIN:
      return {
        ...state,
        show: action.show,
        left: action.left,
        top: action.top,
        inReplyTo: action.inReplyTo
      };
    default:
      return state;
  }
};