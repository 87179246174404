import React, { Component } from 'react'
import FaComment from 'react-icons/lib/fa/th-list'

export default class NumCommentIcon extends Component {
  constructor(props) {
    super(props)
    this.style = {}
    if( props.style ) {
      this.style = {...this.style, ...props.style}
    }
  }

  render() {
    return (
      <FaComment />
    )
  }
}
