import React, { Component } from "react";
import { connect } from "react-redux";

import Button from "../libs/Button";

import { logout } from "../../actions/login.js";


class LogoutIcon extends Component {
    constructor(props) {
        super(props);
        this.style = { fontSize: "2em", color: "#007bff", marginLeft: '25px' };
        if (props.style) {
            this.style = { ...this.style, ...props.style };
        }
    }

    render() {
        return (
            <Button
                onClick={() => this.props.onLogout()}
                styles={this.style}
                bsStyle={"primary"}
            >
                logout
            </Button>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLogout: () => {
            dispatch(logout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutIcon);